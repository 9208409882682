import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const stateReductions = {
  [ACTIONS.REQUEST_STATE]: state => ({
    ...state,
    statesUSStatus: {
      ...state.statesUSStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_STATE]: (state, action) => ({
    ...state,
    statesUS: action.payload.states,
    statesUSStatus: {
      ...state.statesUSStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_STATE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    statesUSStatus: {
      ...state.statesUSStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default stateReductions;
