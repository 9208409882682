import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpoint = '/api/persons/v1';

export default function nameService(client) {
  const getPersonNamesAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/person/${personId}/names`));
  const postPersonNamesAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/persons/names`, payload));
  const postPersonNamesWithIdAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/person/${payload.personId}/names`, payload), 5, 3000);
  const putPersonNamesAsync = async (nameId, payload, nameETag) => {
    const config = { headers: { 'If-Match': nameETag || 'Default' } };
    return retryCallIfNeeded(client, () => client.put(`${baseEndpoint}/person/name/${nameId}`, payload, config));
  };
  return {
    getPersonNamesAsync,
    postPersonNamesAsync,
    putPersonNamesAsync,
    postPersonNamesWithIdAsync,
  };
}
