const ACTIONS = {
  REQUEST_POST_PHONE_CONSENT: 'REQUEST_POST_PHONE_CONSENT',
  RECEIVE_POST_PHONE_CONSENT: 'RECEIVE_POST_PHONE_CONSENT',
  REQUEST_POST_PHONE_CONSENT_ERROR: 'REQUEST_POST_PHONE_CONSENT_ERROR',
  REQUEST_PHONE_CONSENT: 'REQUEST_PHONE_CONSENT',
  RECEIVE_PHONE_CONSENT: 'RECEIVE_PHONE_CONSENT',
  REQUEST_PHONE_CONSENT_ERROR: 'REQUEST_PHONE_CONSENT_ERROR',
  REQUEST_POST_INDIANA_CONSENT: 'REQUEST_POST_INDIANA_CONSENT',
  RECEIVE_POST_INDIANA_CONSENT: 'RECEIVE_POST_INDIANA_CONSENT',
  REQUEST_POST_INDIANA_CONSENT_ERROR: 'REQUEST_POST_INDIANA_CONSENT_ERROR',
  REQUEST_POST_EMAIL_CONSENT: 'REQUEST_POST_EMAIL_CONSENT',
  RECEIVE_POST_EMAIL_CONSENT: 'RECEIVE_POST_EMAIL_CONSENT',
  REQUEST_POST_EMAIL_CONSENT_ERROR: 'REQUEST_POST_EMAIL_CONSENT_ERROR',
};

export default ACTIONS;
