// https://www.npmjs.com/package/whatwg-fetch
// import 'whatwg-fetch'; If there are consumer issues
/* eslint-disable import/no-cycle */
import csrfHeaderRequestInterceptor from '../interceptors/csrfHeaders';
import omitHeaderCookies from '../interceptors/omitHeaderCookies';
import rewriteToProxy from '../interceptors/rewriteToProxy';
import idHeaders from '../interceptors/idHeaders';
import fullstoryHeader from '../interceptors/fullstoryHeader';
// import rewriteToMock from '../interceptors/rewriteToMock';
// import tokenAuthorization from '../interceptors/tokenAuthorization';
import invalidCsrfTokenHandler from '../interceptors/invalidCsrfTokenHandler'; // TODO: Fix
import resourceConflict from '../interceptors/resourceConflict';
import rewriteToMock from '../interceptors/rewriteToMock';
import tokenAuthorization from '../interceptors/tokenAuthorization';
import jsonHandler from '../interceptors/jsonHandler';

const { REACT_APP_BYPASS_PROXY } = process.env;

const requestConfigAppender = (callbacks, baseConfig) => callbacks
  .reduce((accumulator, interceptor) => {
    const updatedConfig = (typeof interceptor === 'function') ? interceptor(accumulator) : {};
    return { ...accumulator, ...updatedConfig };
  }, baseConfig);

const requestFailureHandler = async (callbacks, requestConfig, response, client) => {
  for (let index = 0; index < callbacks.length; index += 1) {
    const callback = callbacks[index];
    const callbackResult = (typeof callback === 'function') && callback(response, requestConfig, client);

    if (callbackResult !== null) return callbackResult;
  }
  return null;
};

const convertPayloadIfJSON = payload => {
  if (!payload) return {};

  try {
    return JSON.parse(payload);
  } catch (e) {
    return payload;
  }
};

const buildResponse = async (request, httpVerb, requestHeaders = {}) => {
  const resolvedRequest = await request;
  return {
    httpVerb,
    data: convertPayloadIfJSON(await resolvedRequest.text()),
    url: resolvedRequest.url,
    headers: resolvedRequest.headers,
    status: resolvedRequest.status,
    statusText: resolvedRequest.statusText,
    requestHeaders,
  };
};

const requestHandler = async (
  client, url, body, config, verb,
  requestInterceptors, responseInterceptors,
) => {
  const requestConfig = requestConfigAppender(requestInterceptors, {
    ...config,
    ...((config.headers || '') !== '' && {
      headers: {
        ...config.headers,
      },
    }),
    ...((url || '') !== '' && { url }),
    ...((verb || '') !== '' && { method: verb }),
    ...((body || '') !== '' && { body }),
  });

  const response = await window.fetch(requestConfig.url, requestConfig);
  if (response.ok) {
    return response;
  }

  const res = await requestFailureHandler(
    responseInterceptors, requestConfig, response, client,
  );
  if (res === null) {
    throw await buildResponse(response, verb, requestConfig.headers);
  }

  return res;
};

class FetchClient {
  constructor() {
    this.requestInterceptor = [];
    this.responseInterceptor = [];
    this.client = this.client.bind(this);
  }

  applyRequestInterceptor(callback) {
    this.requestInterceptor.push(callback);
  }

  applyResponseInterceptor(callback) {
    this.responseInterceptor.push(callback);
  }

  client() {
    return {
      get: async (url, config = {}) => buildResponse(
        requestHandler(
          this.client(), url, undefined, config, 'GET',
          this.requestInterceptor, this.responseInterceptor,
        ),
        'GET',
        config.headers,
      ),
      post: async (url, body, config = {}) => buildResponse(
        requestHandler(
          this.client(), url, JSON.stringify(body), config, 'POST',
          this.requestInterceptor, this.responseInterceptor,
        ),
        'POST',
        config.headers,
      ),
      put: async (url, body, config = {}) => buildResponse(
        requestHandler(
          this.client(), url, JSON.stringify(body), config, 'PUT',
          this.requestInterceptor, this.responseInterceptor,
        ),
        'PUT',
        config.headers,
      ),
      patch: async (url, body, config = {}) => buildResponse(
        requestHandler(
          this.client(), url, JSON.stringify(body), config, 'PATCH',
          this.requestInterceptor, this.responseInterceptor,
        ),
        'PATCH',
        config.headers,
      ),
      delete: async (url, config = {}) => buildResponse(
        requestHandler(
          this.client(), url, undefined, config, 'DELETE',
          this.requestInterceptor, this.responseInterceptor,
        ),
        'DELETE',
        config.headers,
      ),
      request: async config => {
        const response = requestHandler(
          this.client(), undefined, undefined, config, undefined,
          this.requestInterceptor, this.responseInterceptor,
        );
        return response;
      },
    };
  }
}

const proxyClient = () => {
  const fetchClient = new FetchClient();
  fetchClient.applyRequestInterceptor(csrfHeaderRequestInterceptor);
  fetchClient.applyRequestInterceptor(omitHeaderCookies);

  fetchClient.applyRequestInterceptor(tokenAuthorization);
  fetchClient.applyRequestInterceptor(idHeaders);
  fetchClient.applyRequestInterceptor(fullstoryHeader);
  if (REACT_APP_BYPASS_PROXY === 'true') {
    fetchClient.applyRequestInterceptor(rewriteToMock);
  } else {
    fetchClient.applyRequestInterceptor(rewriteToProxy);
  }
  fetchClient.applyRequestInterceptor(jsonHandler);

  fetchClient.applyResponseInterceptor(resourceConflict);
  fetchClient.applyResponseInterceptor(invalidCsrfTokenHandler);

  return fetchClient.client();
};

export const proxyClientInstance = proxyClient();
export default proxyClient();
