import createReducer from '../../helpers/reducerHelper';
import countryReductions from './reductions/countryReductions';
import stateReductions from './reductions/stateReductions';
import provinceReductions from './reductions/provinceReductions';
import militaryCountryReductions from './reductions/militaryCountryReductions';
import militaryStateReductions from './reductions/militaryStateReductions';
import militaryCityReductions from './reductions/militaryCityReductions';
import districtReductions from './reductions/districtReductions';
import outlyingAreaReductions from './reductions/outlyingAreaReductions';

const initialState = { enumReducer: {} };

export const enumReducer = createReducer(initialState, {
  ...countryReductions,
  ...stateReductions,
  ...provinceReductions,
  ...militaryCountryReductions,
  ...militaryStateReductions,
  ...militaryCityReductions,
  ...districtReductions,
  ...outlyingAreaReductions,
});

export default enumReducer;
