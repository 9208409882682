import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personName = (
  state = { meta: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PERSON_META:
      return {
        ...state,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_META:
      return {
        ...state,
        meta: action.meta,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_META_ERROR:
      return {
        ...state,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_META_MISSING:
      return {
        ...state,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.MISSING,
        },
      };

    default:
      return state;
  }
};

export default personName;
