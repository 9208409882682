/* eslint-disable no-template-curly-in-string */
// https://github.com/jquense/yup#extending-schema-types
// Test exclusive is false by default
import * as yup from 'yup';

const extensionMethodName = 'isValidAreaCode';

function isValidAreaCodeHandler(countryField,
  message, { areaCodes }) {
  return this.test({
    name: 'isValidAreaCode',
    exclusive: false,
    message: message || 'A valid ${country} area code is required',
    params: {
      country: countryField,
    },
    test(value) {
      const countryToValidate = this.resolve(countryField);
      const countryAreaCodes = areaCodes[countryToValidate];
      if ((countryAreaCodes || []).length > 0) {
        // ! Built for US and CA
        const pattern = /(?:^(?:\+1)(\d{3})|\((\d{3})\))/;
        const patternMatch = (pattern.exec(value) || []);
        const areaCodeMatch = patternMatch[1] || patternMatch[2];
        return countryAreaCodes.includes(areaCodeMatch);
      }
      return true;
    },
  });
}

yup.addMethod(yup.mixed, extensionMethodName, isValidAreaCodeHandler);
