import {
  createUserManager,
} from 'redux-oidc';
import {
  WebStorageStateStore,
  Log,
} from 'oidc-client';

// eslint-disable-next-line no-underscore-dangle
if ((window.__ENV || {}).REACT_APP_OIDC_DEBUG === 'true') {
  Log.logger = console;
  Log.level = Log.DEBUG;
}

const userManagerConfig = {
  // eslint-disable-next-line no-underscore-dangle
  authority: (window.__ENV || {}).REACT_APP_OAUTH_AUTHORITY,
  // eslint-disable-next-line no-underscore-dangle
  client_id: (window.__ENV || {}).REACT_APP_OAUTH_CLIENT_ID,
  // eslint-disable-next-line no-underscore-dangle
  redirect_uri: (window.__ENV || {}).REACT_APP_OAUTH_REDIRECT_URI,
  // eslint-disable-next-line no-underscore-dangle
  silent_redirect_uri: (window.__ENV || {}).REACT_APP_OAUTH_SILENT_RENEW_URI,
  // eslint-disable-next-line no-underscore-dangle
  post_logout_redirect_uri: (window.__ENV || {}).REACT_APP_LOGOUT_URL,
  response_type: 'code',
  // eslint-disable-next-line no-underscore-dangle
  scope: (window.__ENV || {}).REACT_APP_OAUTH_SCOPE,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  // The time in seconds to refresh before the token expired time in seconds
  accessTokenExpiringNotificationTime: 300,
  // Remove the default storing of token information in session storage
  userStore: new WebStorageStateStore({
    store: {
      getItem: () => null,
      setItem: () => null,
      removeItem: () => null,
    },
  }),
  stateStore: new WebStorageStateStore({ prefix: 'become.', store: window.sessionStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
