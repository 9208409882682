import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personInformation = (
  state = { information: {} },
  action,
) => {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_PERSON_INFO:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInformationStatus: {
          ...state.personInformationStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_INFO_ERROR:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_INFO_MISSING:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          getStatus: STATUS.MISSING,
        },
      };

    // POST
    case ACTIONS.REQUEST_POST_PERSON_INFO:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON_INFO_ERROR:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT
    case ACTIONS.REQUEST_PUT_PERSON_INFO:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PUT_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PUT_PERSON_INFO_ERROR:
      return {
        ...state,
        personInformationStatus: {
          ...state.personInformationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // CLEAR STATUSES
    case ACTIONS.CLEAR_PERSON_INFO_MODIFY_STATUS:
      return {
        ...state,
        personInformationStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
};

export default personInformation;
