/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isDate';

function isDateHandler(message, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message: message || '${path} cannot be in the past',
    test(value) {
      return !value || moment(value, dateFormat, true).isValid();
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isDateHandler);
