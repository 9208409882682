import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const districtReductions = {
  [ACTIONS.REQUEST_DISTRICT]: state => ({
    ...state,
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_DISTRICT]: (state, action) => ({
    ...state,
    districtsUS: action.payload.districtsUS,
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_DISTRICT_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    districtsStatus: {
      ...state.districtsStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default districtReductions;
