import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpoint = '/api/contacts/v1';

export default function phoneService(client) {
  const getContactPhoneAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/phones?linkId=${personId}&linkType=person`));
  const postContactPhoneAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/phone`, payload));
  const putContactPhoneAsync = async (phoneId, payload) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpoint}/phone/${phoneId}`, payload));
  return {
    getContactPhoneAsync,
    postContactPhoneAsync,
    putContactPhoneAsync,
  };
}
