import retryCallIfNeeded from '../../helpers/restCalls';

export default function associationService(client) {
  const getPersonAssociationsAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/associations`),
  );

  const getPersonAssociationAsync = async (
    personId,
    associationId,
  ) => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/association/${associationId}`),
  );

  const postPersonAssociationAsync = async (personId, payload) => retryCallIfNeeded(
    client,
    () => client.post(`/api/persons/v1/person/${personId}/association`, payload),
  );

  const putPersonAssociationAsync = async (
    personId,
    associationId,
    payload,
    etag,
  ) => retryCallIfNeeded(
    client,
    () => client.put(`/api/persons/v1/person/${personId}/association/${associationId}`, payload, {
      headers: {
        'If-Match': etag,
      },
    }),
  );

  const deletePersonAssociationAsync = async (associationId, etag) => retryCallIfNeeded(
    client,
    () => client.delete(`/api/persons/v1/person/association/${associationId}`, {
      headers: {
        'If-Match': etag,
      },
    }),
  );

  return {
    getPersonAssociationsAsync,
    getPersonAssociationAsync,
    postPersonAssociationAsync,
    putPersonAssociationAsync,
    deletePersonAssociationAsync,
  };
}
