// https://jsben.ch/4iDft performance check
const getFullStoryUrl = () => ((typeof window.FS?.getCurrentSessionURL === 'function')
  ? window.FS.getCurrentSessionURL(true) || '' : '');

const fullstoryUrlHeader = 'X-FullStory-URL';

export default requestConfig => {
  const fullstoryUrl = getFullStoryUrl();

  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      [fullstoryUrlHeader]: fullstoryUrl,
    },
  };
};
