import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpointUrl = '/api/iam/v1';

export default function IamService(client) {
  const postValidateUserByUsernameAndPasswordAsync = async (username, password) => retryCallIfNeeded(client, () => client.post(`${baseEndpointUrl}/user/${username}/validate`,
    {
      username,
      password,
    }));

  const getUsernameAvailabilityAsync = username => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/user/availability?username=${encodeURIComponent(username)}`));
  const getEmailAvailabilityAsync = email => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/user/availability?email=${encodeURIComponent(email)}`));
  const postUserAsync = payload => retryCallIfNeeded(client, () => client.post('/api/iam/v1/users', payload));
  const getTokeByTokenIdAsync = tokenId => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/token/${tokenId}`));
  const putTokenAsync = (payload, tokenId) => retryCallIfNeeded(client, () => client.put(`${baseEndpointUrl}/token/${tokenId}`, payload));

  return {
    postValidateUserByUsernameAndPasswordAsync,
    getUsernameAvailabilityAsync,
    getEmailAvailabilityAsync,
    postUserAsync,
    getTokeByTokenIdAsync,
    putTokenAsync,
  };
}
