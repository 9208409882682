/* eslint-disable no-underscore-dangle */
import STATUS from '../../globalStatuses';
import { ACTIONS } from './types';
import clientDetails from '../../helpers/clientDetails';
import { getPersonId, shouldFetch, createRequestAction } from '../../../helpers/commonHelpers';
import { consentText } from '../../../components/consent/Consent';

const { REACT_APP_IMS_OWNER_NT_LOGIN } = window.__ENV || {};

// GET
const requestWorkflow = () => ({
  type: ACTIONS.REQUEST_WORKFLOW,
});

const receiveWorkflow = data => ({
  type: ACTIONS.RECEIVE_WORKFLOW,
  workflow: data,
});

const requestWorkflowError = error => createRequestAction(
  error, ACTIONS.REQUEST_WORKFLOW_MISSING,
  ACTIONS.REQUEST_WORKFLOW_ERROR,
);

function shouldFetchWorkFlow(getState) {
  return (
    shouldFetch(getState().saga.workflowStatus)
    || getState().saga.workflowStatus.getStatus === STATUS.ERROR
    || getState().saga.workflow?.length < 1
  );
}

const getWorkflowByPersonId = personId => (
  async (dispatch, getState, { Services }) => {
    dispatch(requestWorkflow());
    try {
      const responseData = (await Services.sagasService.getWorkflowByPersonIdAsync(personId)).data;
      if (responseData.length > 0) {
        dispatch(receiveWorkflow(responseData));
      } else {
        dispatch(requestWorkflowError('No data found'));
      }
    } catch (error) {
      dispatch(requestWorkflowError(error));
    }
  }
);

export const getWorkflowByPersonIdIfNeeded = personId => (dispatch, getState) => {
  if (shouldFetchWorkFlow(getState)) {
    dispatch(getWorkflowByPersonId(personId));
  }
};

const requestPersonTasks = () => ({
  type: ACTIONS.REQUEST_PERSON_TASK,
});

const receivePersonTasks = data => ({
  type: ACTIONS.RECEIVE_PERSON_TASK,
  personTasks: data,
});

const requestPersonTasksError = error => ({
  type: ACTIONS.REQUEST_PERSON_TASK_ERROR,
  error,
});

function shouldFetchPersonTasks(getState) {
  return (
    getState().saga.personTasksStatus.getStatus === STATUS.UNFETCHED
      || getState().saga.personTasksStatus.getStatus === STATUS.ERROR
  );
}

const getPersonTasksByPersonId = personId => (
  async (dispatch, _, { Services }) => {
    dispatch(requestPersonTasks());
    try {
      const responseData = (await Services.sagasService.getPersonTasksAsync(personId)).data;
      if (!responseData) {
        throw new Error('Person tasks could not be retrieved.');
      }
      dispatch(receivePersonTasks(responseData || {}));
    } catch (error) {
      dispatch(requestPersonTasksError(error));
    }
  }
);

export const getPersonTasksByPersonIdIfNeeded = personId => (dispatch, getState) => {
  if (shouldFetchPersonTasks(getState)) {
    dispatch(getPersonTasksByPersonId(personId));
  }
};

// POST
const requestStartWorkflow = () => ({
  type: ACTIONS.REQUEST_START_WORKFLOW,
});

const receiveStartWorkflow = workflowInstanceId => ({
  type: ACTIONS.RECEIVE_START_WORKFLOW,
  workflowInstanceId,
});

const requestStartWorkflowError = error => ({
  type: ACTIONS.REQUEST_START_WORKFLOW_ERROR,
  error,
});

export const startWorkFlow = () => async (dispatch, getState, { Services }) => {
  dispatch(requestStartWorkflow());
  try {
    const state = getState();
    const workFlowName = 'NAP';
    const workFlowVersion = 1;
    const personId = getPersonId(state);
    const pId = personId || sessionStorage.getItem('personId');
    const responseData = (await Services.sagasService
      .startWorkFlowAsync(pId, workFlowName, workFlowVersion)).data;
    if (responseData) {
      dispatch(receiveStartWorkflow(responseData));
    } else {
      dispatch(requestStartWorkflowError(`Issue with starting WorkFlow ${workFlowName}`));
    }
  } catch (error) {
    dispatch(requestStartWorkflowError(error));
  }
};

const requestCompleteWorkFlowTask = taskName => ({
  type: ACTIONS.REQUEST_COMPLETE_WORKFLOW_TASK,
  payload: { taskName },
});

const receiveCompleteWorkFlowTask = (data, taskName) => ({
  type: ACTIONS.RECEIVE_COMPLETE_WORKFLOW_TASK,
  payload: { data, taskName },
});

const requestCompleteWorkFlowTaskError = (error, taskName) => ({
  type: ACTIONS.REQUEST_COMPLETE_WORKFLOW_TASK_ERROR,
  payload: { error, taskName },
});

export const completeWorkFlowTask = (taskName, optionalData = {}) => (
  async (dispatch, getState, { Services }) => {
    dispatch(requestCompleteWorkFlowTask(taskName));
    try {
      const state = getState();
      const personId = getPersonId(state);
      const { workflowInstanceId } = state.saga;
      const program = { code: state.cartResources.ur_program };
      const appContext = 'NAPSHOPCART';
      const anticipatedStartDate = state.cartResources.ur_anticipatedStartDate;
      const ownerNTLogin = REACT_APP_IMS_OWNER_NT_LOGIN;
      const campusOrgaNumber = state.cartResources.ur_campus;
      const campaign = 'SHOPPING_C'; // PREX-28706 Hard coded due to business requirements
      const source = 'INET';
      const { requestingIP } = clientDetails;
      const { mcid } = clientDetails;
      const synthetic = sessionStorage.getItem('synthetic') || false;
      const requestingUrl = window.location.href;
      const referringUrl = window.location.href;
      const disclaimer = consentText;
      const { userAgent } = navigator;
      const isMobileDevice = /Mobi/.test(navigator.userAgent);

      const { stateConsentDisclaimer, forgerockEnabled } = optionalData;

      const payload = {
        workflowInstanceId,
        personId,
        mcid,
        taskName,
        appContext,
        program,
        campusOrgaNumber,
        campaign,
        source,
        requestingIP,
        anticipatedStartDate,
        ownerNTLogin,
        synthetic,
        disclaimer,
        requestingUrl,
        referringUrl,
        userAgent,
        isMobileDevice,
        forgerockEnabled,
      };

      if (stateConsentDisclaimer) payload.stateConsentDisclaimer = stateConsentDisclaimer;

      const responseData = (await Services.sagasService.completeTaskAsync(payload)).data;
      if (responseData) {
        dispatch(receiveCompleteWorkFlowTask(responseData, taskName));
      } else {
        const errorMessage = `Issue with completing WF Task ${taskName}`;
        dispatch(requestCompleteWorkFlowTaskError(errorMessage, taskName));
      }
    } catch (error) {
      dispatch(requestCompleteWorkFlowTaskError(error, taskName));
    }
  }
);

// PUT
const requestCompletePersonTask = () => ({
  type: ACTIONS.REQUEST_COMPLETE_PERSON_TASK,
});

const receiveCompletePersonTask = data => ({
  type: ACTIONS.RECEIVE_COMPLETE_PERSON_TASK,
  personTasks: data,
});

const requestCompletePersonTaskError = error => ({
  type: ACTIONS.REQUEST_COMPLETE_PERSON_TASK_ERROR,
  error,
});

export const completePersonTask = (personId, taskId) => (
  async (dispatch, _, { Services }) => {
    dispatch(requestCompletePersonTask());
    try {
      const responseData = (await Services.sagasService
        .completePersonTaskAsync(personId, taskId)).data;
      if (!responseData) {
        throw new Error('Person task could not be completed.');
      }
      dispatch(receiveCompletePersonTask(responseData || {}));
    } catch (error) {
      dispatch(requestCompletePersonTaskError(error));
    }
  }
);

export default {
  startWorkFlow,
  getWorkflowByPersonIdIfNeeded,
  completeWorkFlowTask,
  getPersonTasksByPersonIdIfNeeded,
  completePersonTask,
};
