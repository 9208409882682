/* eslint-disable no-underscore-dangle */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  UOPX_PHONE_NUMBER,
  phoneNumber10Digit,
} from '../../modules/new-account/constants';

export const consentText = `By clicking 'Save and continue', you are giving your express written consent for University of Phoenix to contact you regarding our educational programs and services using email, telephone or text - including our use of automated technology for calls, pre-recorded messages and periodic texts to any number you provide, including any wireless number. Message and data rates may apply. This consent is not required to purchase goods or services. This consent is not required to complete an application for admission to University of Phoenix. If you wish to complete an application for admission without providing this consent, please request information by contacting the University directly at ${UOPX_PHONE_NUMBER}. If you no longer wish to receive marketing text messages, reply STOP to cancel such future text messages. If you no longer wish to receive automated marketing phone calls, you may opt out by expressing your preference to an enrollment representative or by emailing Office.ComplianceUOPX@phoenix.edu. By entering your email address you are consenting to the University sending you notifications, updates, requests for information, and/or marketing information concerning University of Phoenix.`;

export const ConsentText = () => (
  <>
    By clicking &lsquo;Continue&rsquo;, you are giving your express written
    consent for University of Phoenix to contact you regarding our educational
    programs and services using telephone or text - including our use of
    automated technology for calls, pre-recorded messages and periodic texts to
    any number you provide, including any wireless number. Message and data
    rates may apply. This consent is not required to purchase goods or services.
    This consent is not required to complete an application for admission to
    University of Phoenix. If you wish to complete an application for admission
    without providing this consent, please request information by contacting the
    University directly at
    {' '}
    <a
      href={`tel:+1${phoneNumber10Digit(UOPX_PHONE_NUMBER)}`}
      rel="noopener noreferrer"
      aria-label="Call Us"
      className="textLink"
    >
      {UOPX_PHONE_NUMBER}
    </a>
    .
    <br />
    <br />
    By clicking ‘Continue’, you also consent to receiving University of Phoenix
    emails about our educational programs and services.
    {' '}
  </>
);

export const ConsentEditText = () => (
  <>
    By clicking &lsquo;Continue&rsquo;, you are giving your express written
    consent for University of Phoenix to contact you regarding our educational
    programs and services using email, telephone or text - including our use of
    automated technology for calls, pre-recorded messages and periodic texts to
    any number you provide, including any wireless number. Message and data
    rates may apply. This consent is not required to purchase goods or services.
    This consent is not required to complete an application for admission to
    University of Phoenix. If you wish to complete an application for admission
    without providing this consent, please request information by contacting the
    University directly at
    {' '}
    <a
      href={`tel:+1${phoneNumber10Digit(UOPX_PHONE_NUMBER)}`}
      rel="noopener noreferrer"
      aria-label="Call Us"
      className="textLink"
    >
      {UOPX_PHONE_NUMBER}
    </a>
    .
    <br />
    <br />
    By clicking ‘Continue’, you also consent to receiving University of Phoenix
    emails about our educational programs and services.
    {' '}
  </>
);

const Consent = ({ isEditVersion }) => (
  <div style={{ paddingTop: '2rem' }}>
    <Typography className="notes">
      {!isEditVersion ? <ConsentText /> : <ConsentEditText />}
    </Typography>
    <br />
    <Typography className="notes">
      Visit our&nbsp;
      <a
        href="https://www.phoenix.edu/copyright-legal/privacy_policy.html"
        aria-label="Privacy policy"
        rel="noopener noreferrer"
        target="_blank"
        className="textLink"
      >
        Privacy Policy
      </a>
      &nbsp;for information about how we collect and use your personal
      information.
    </Typography>
  </div>
);

Consent.propTypes = {
  isEditVersion: PropTypes.bool.isRequired,
};

Consent.defaultProps = {};

export default Consent;
