import STATUS from '../../globalStatuses';
import ACTIONS from './types';

function person(
  state = {
    person: {},
  },
  action,
) {
  switch (action.type) {
    // GET Person
    case ACTIONS.REQUEST_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.etag,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_ERROR:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_MISSING:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.MISSING,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON_ERROR:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.ERROR,
        },
      };
    case ACTIONS.RECEIVE_POST_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.etag,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATED,
          errorData: action.error,
        },
        names: [
          {
            personId: action.person.id || action.person.personId,
            firstName: action.person.firstName,
            lastName: action.person.lastName,
          },
        ],
      };
    case ACTIONS.REQUEST_UPDATE_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.etag,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_UPDATE_PERSON_ERROR:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.MULTISTEP_CREATE_ACCOUNT_START:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.MULTISTEP_CREATE_ACCOUNT_COMPLETION:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.UPDATED,
          errorData: null,
        },
      };
    case ACTIONS.MULTISTEP_CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default person;
