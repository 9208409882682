import STATUS from '../../../globalStatuses';
import actions from '../../saga/actions';
import ACTIONS from './types';

const contactConsent = (
  state = { contactConsent: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_POST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsent: action.phoneConsent,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_PHONE_CONSENT_ERROR:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_POST_INDIANA_CONSENT:
      return {
        ...state,
        constactConsent :{
        ...state.contactConsent,
          indianaConsent: action.indianaConsent, 
          indianaConsentStatus: {
          ...state.indianaConsentStatus,
            modifyStatus: STATUS.UPDATING,
            errorData: null,
          },
        }  
      };
    case ACTIONS.RECEIVE_POST_INDIANA_CONSENT:
      return {
        ...state,
        contactConsent :{
        ...state.contactConsent,
          indianaConsent: action.indianaConsent,
          indianaConsentStatus: {
            ...state.indianaConsentStatus,
            modifyStatus: STATUS.UPDATED,
          }   
        },
      };
    case ACTIONS.REQUEST_POST_INDIANA_CONSENT_ERROR:
      return {
        ...state,
        contactConsent :{
          ...state.contactConsent,
            indianaConsent: action.indianaConsent,
            indianaConsentStatus: {
              ...state.indianaConsentStatus,
              modifyStatus: STATUS.ERROR,
              errorData: action.error,
            } 
          },
      };  

    case ACTIONS.REQUEST_POST_EMAIL_CONSENT:
      return {
        ...state,
        emailConsentStatus: {
          ...state.emailConsentStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_EMAIL_CONSENT:
      return {
        ...state,
        emailConsent: action.emailConsent,
        emailConsentStatus: {
          ...state.emailConsentStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_EMAIL_CONSENT_ERROR:
      return {
        ...state,
        emailConsentStatus: {
          ...state.emailConsentStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PHONE_CONSENT:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PHONE_CONSENT:
      return {
        ...state,
        phoneConsent: action.phoneConsent,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PHONE_CONSENT_ERROR:
      return {
        ...state,
        phoneConsentStatus: {
          ...state.phoneConsentStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };


    default:
      return state;
  }
};

export default contactConsent;
