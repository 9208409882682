import { v4 as uuidv4 } from 'uuid';

const xCorrelationIdName = 'X-Correlation-Id';
const xRequestIdName = 'X-Request-Id';
const contentType = 'application/json';
const syntheticHeader = 'x-uopx-synthetic';

const xCorrelationId = sessionStorage.getItem(xCorrelationIdName) || sessionStorage.getItem('mcId') || uuidv4();
sessionStorage.setItem(xCorrelationIdName, xCorrelationId);

export default requestConfig => ({
  ...requestConfig,
  headers: {
    ...requestConfig?.headers,
    [xCorrelationIdName]: xCorrelationId,
    [xRequestIdName]: uuidv4(),
    'Content-Type': contentType,
    ...(sessionStorage.getItem('synthetic') === 'true' && { [syntheticHeader]: 'true' }),
  },
});
