import STATUS from '../../globalStatuses';
import ACTIONS from './types';

function personDestiny(
  state = {
    personDestiny: { areDependenciesFulfilled: false },
  },
  action,
) {
  switch (action.type) {
    // GET Person
    case ACTIONS.REQUEST_PERSON_DESTINY:
      return {
        ...state,
        areDependenciesFulfilled: false,
        personDestinyStatus: {
          ...state.personDestinyStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_DESTINY:
      return {
        ...state,
        areDependenciesFulfilled: action.result,
        personDestinyStatus: {
          ...state.personDestinyStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_DESTINY_ERROR:
      return {
        ...state,
        areDependenciesFulfilled: false,
        personDestinyStatus: {
          ...state.personDestinyStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
}

export default personDestiny;
