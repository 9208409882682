import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const militaryCityReductions = {
  [ACTIONS.REQUEST_MILITARY_CITY]: state => ({
    ...state,
    militaryCitiesStatus: {
      ...state.militaryCitiesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_CITY]: (state, action) => ({
    ...state,
    militaryCities: action.payload.militaryCities,
    militaryCitiesStatus: {
      ...state.militaryCitiesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_MILITARY_CITY_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    militaryCitiesStatus: {
      ...state.militaryCitiesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default militaryCityReductions;
