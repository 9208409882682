export const ACTIONS = {
  REQUEST_START_WORKFLOW: 'REQUEST_START_WORKFLOW',
  RECEIVE_START_WORKFLOW: 'RECEIVE_START_WORKFLOW',
  REQUEST_START_WORKFLOW_ERROR: 'RECEIVE_START_WORKFLOW_ERROR',

  REQUEST_WORKFLOW: 'REQUEST_WORKFLOW',
  RECEIVE_WORKFLOW: 'RECEIVE_WORKFLOW',
  REQUEST_WORKFLOW_MISSING: 'REQUEST_WORKFLOW_MISSING',
  REQUEST_WORKFLOW_ERROR: 'RECEIVE_WORKFLOW_ERROR',

  REQUEST_COMPLETE_WORKFLOW_TASK: 'REQUEST_COMPLETE_WORKFLOW_TASK',
  RECEIVE_COMPLETE_WORKFLOW_TASK: 'RECEIVE_COMPLETE_WORKFLOW_TASK',
  REQUEST_COMPLETE_WORKFLOW_TASK_ERROR: 'REQUEST_COMPLETE_WORKFLOW_TASK_ERROR',

  REQUEST_PERSON_TASK: 'REQUEST_PERSON_TASK',
  RECEIVE_PERSON_TASK: 'RECEIVE_PERSON_TASK',
  REQUEST_PERSON_TASK_ERROR: 'REQUEST_PERSON_TASK_ERROR',

  REQUEST_COMPLETE_PERSON_TASK: 'REQUEST_COMPLETE_PERSON_TASK',
  RECEIVE_COMPLETE_PERSON_TASK: 'RECEIVE_COMPLETE_PERSON_TASK',
  REQUEST_COMPLETE_PERSON_TASK_ERROR: 'REQUEST_COMPLETE_PERSON_TASK_ERROR',
};

export const WORKFLOW_TASK_NAMES = {
  PERSONAL_INFORMATION: 'personalInformation',
  BACKGROUND: 'background',
  REVIEW: 'review',
};

export const getWorkflowKeyByValue = value => Object.keys(WORKFLOW_TASK_NAMES)
  .find(key => WORKFLOW_TASK_NAMES[key] === value);
