const retryCountHeaderName = 'X-Conflict-Retry-Attempt';
const etagHeaderName = 'etag';
const statusCode = 409;
const maxRetries = 2;

export default (response, requestConfig, client) => {
  const retryAttempts = response.headers.get(retryCountHeaderName) || 0;
  if ((requestConfig.method.toLowerCase() === 'put' || requestConfig.method.toLowerCase() === 'delete')
    && response.status === statusCode
    && retryAttempts < maxRetries) {
    const reRequestConfig = {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        [retryCountHeaderName]: retryAttempts + 1,
        'If-Match': response.headers.get(etagHeaderName),
      },
    };
    return client.request(reRequestConfig);
  }
  return null;
};
