import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personGenericInfo = (
  state = { personGenericInfo: {} },
  action,
) => {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_PERSON_GENERICINFO:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_GENERICINFO:
      return {
        ...state,
        genericInfo: action.genericInfo,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_GENERICINFO_ERROR:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_GENERICINFO_MISSING:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.MISSING,
        },
      };
    default:
      return state;
  }
};

export default personGenericInfo;
