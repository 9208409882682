import retryCallIfNeeded from '../../helpers/restCalls';

const walletEndpoint = '/api/wallet/v1';

export default function walletService(client) {
  const getSsnByPersonIdAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`${walletEndpoint}/wallets/?personId=${personId}`),
  );
  const postSsnByPersonIdAsync = async payload => retryCallIfNeeded(
    client, () => client.post(`${walletEndpoint}/wallets`, payload),
  );
  const putSsnByPersonIdAsync = async payload => retryCallIfNeeded(
    client, () => client.put(`${walletEndpoint}/wallets`, payload),
  );
  const deleteSsnByPersonIdAsync = async personId => retryCallIfNeeded(
    client,
    () => client.delete(`${walletEndpoint}/wallets/?personId=${personId}`),
  );
  return {
    getSsnByPersonIdAsync,
    postSsnByPersonIdAsync,
    putSsnByPersonIdAsync,
    deleteSsnByPersonIdAsync,
  };
}
