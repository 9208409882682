/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isDateLessThanAgeLimit';

function isDateLessThanAgeLimitHandler(ageLimit, message, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message,
    test(value) {
      let isLessThan = true;
      if (value) {
        const today = moment().format(dateFormat);
        const todayVal = moment(today, dateFormat);
        const diff = todayVal.diff(moment(value, dateFormat, true), 'year');
        const diffYear = diff < 0 ? diff * -1 : diff;
        isLessThan = diffYear < 99;
      }
      return isLessThan;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isDateLessThanAgeLimitHandler);
