import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#F2F2F2',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  icon: {
    fontSize: theme.spacing(4.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.2),
    },
  },
}));
