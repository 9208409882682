/* eslint-disable import/no-cycle */
import { proxyClientInstance } from './wrappers/fetch';
import sagasService from './sagas/v1/sagas';
import walletService from './wallet/v1/wallet';
import informationService from './person/v1/information';
import militaryService from './person/v1/military';
import nameService from './person/v1/name';
import emailService from './contact/v1/email';
import phoneService from './contact/v1/phone';
import addressService from './contact/v1/address';
import citizenshipService from './person/v1/citizenship';
import associationService from './person/v1/association';
import genericInfoService from './person/v1/genericinfo';
import enumService from './enum/v1/enum';
import iamService from './iam/v1/iam';
import personService from './person/v1/person';
import consentService from './contact/v1/consent';
import csrfService from './csrf/csrf';
import casService from './cas/cas';
import ForgerockService from './forgerock/forgerock';

export default {
  sagasService: sagasService(proxyClientInstance),
  walletService: walletService(proxyClientInstance),
  citizenshipService: citizenshipService(proxyClientInstance),
  associationService: associationService(proxyClientInstance),
  genericInfoService: genericInfoService(proxyClientInstance),
  informationService: informationService(proxyClientInstance),
  militaryService: militaryService(proxyClientInstance),
  nameService: nameService(proxyClientInstance),
  emailService: emailService(proxyClientInstance),
  phoneService: phoneService(proxyClientInstance),
  addressService: addressService(proxyClientInstance),
  enumerationService: enumService(proxyClientInstance),
  consentService: consentService(proxyClientInstance),
  csrfService: csrfService(proxyClientInstance),
  personService: personService(proxyClientInstance),
  iamService: iamService(proxyClientInstance),
  casService: casService(proxyClientInstance),
  forgerockService: ForgerockService(),
};
