const ACTIONS = {
  REQUEST_PERSON_INFO: 'REQUEST_PERSON_INFO',
  RECEIVE_PERSON_INFO: 'RECEIVE_PERSON_INFO',
  REQUEST_PERSON_INFO_MISSING: 'REQUEST_PERSON_INFO_MISSING',
  REQUEST_PERSON_INFO_ERROR: 'REQUEST_PERSON_INFO_ERROR',

  REQUEST_POST_PERSON_INFO: 'REQUEST_POST_PERSON_INFO',
  RECEIVE_POST_PERSON_INFO: 'RECEIVE_POST_PERSON_INFO',
  REQUEST_POST_PERSON_INFO_ERROR: 'REQUEST_POST_PERSON_INFO_ERROR',

  REQUEST_PUT_PERSON_INFO: 'REQUEST_PUT_PERSON_INFO',
  RECEIVE_PUT_PERSON_INFO: 'RECEIVE_PUT_PERSON_INFO',
  REQUEST_PUT_PERSON_INFO_ERROR: 'REQUEST_PUT_PERSON_INFO_ERROR',

  CLEAR_PERSON_INFO_MODIFY_STATUS: 'CLEAR_PERSON_INFO_MODIFY_STATUS',
};

export default ACTIONS;
