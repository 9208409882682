import STATUS from '../../globalStatuses';
import ACTIONS from './types';

function forgerock(
  state = { },
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_POST_FORGEROCK:
      return {
        ...state,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.UPDATING,
          errorDate: null,
        },
      };
    case ACTIONS.RECEIVE_POST_FORGEROCK:
      return {
        ...state,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.UPDATED,
          errorDate: null,
        },
      };
    case ACTIONS.REQUEST_POST_FORGEROCK_ERROR:
      return {
        ...state,
        forgerockStatus: {
          ...state.forgerockStatus,
          modifyStatus: STATUS.ERROR,
          errorDate: action.error,
        },
      };
    default:
      return state;
  }
}

export default forgerock;
