import STATUS from '../../globalStatuses';
import ACTIONS from './types';

const user = (
  state = { user: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_POST_USER:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_USER:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_USER_ERROR:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
};

export default user;
