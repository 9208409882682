/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isPastDate';

function isPastDateHandler(ref, message, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message,
    test(value) {
      const compareDate = this.resolve(ref);
      return !value || moment(compareDate, dateFormat, true).diff(moment(value, dateFormat, true), 'day') > 0;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isPastDateHandler);
