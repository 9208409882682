const HighestEducationFieldTypes = [
  {
    label: 'High School',
    value: 'High School',
  },
  {
    label: 'Associates',
    value: 'Associates',
  },
  {
    label: 'Bachelors',
    value: 'Bachelors',
  },
  {
    label: 'Masters',
    value: 'Masters',
  },
  {
    label: 'Doctorate',
    value: 'Doctorate',
  },
];

export default HighestEducationFieldTypes;
