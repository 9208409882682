import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personMilitary = (
  state = { personMilitary: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_MILITARY_ERROR:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_MILITARY_MISSING:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          getStatus: STATUS.MISSING,
        },
      };

    // POST
    case ACTIONS.REQUEST_POST_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON_MILITARY_ERROR:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT
    case ACTIONS.REQUEST_PUT_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PUT_PERSON_MILITARY:
      return {
        ...state,
        militaryETag: action.etag,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PUT_PERSON_MILITARY_ERROR:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE Military
    case ACTIONS.REQUEST_DELETE_PERSON_MILITARY:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_DELETE_PERSON_MILITARY:
      return {
        ...state,
        military: action.military,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_PERSON_MILITARY_ERROR:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // Clear Statuses
    case ACTIONS.CLEAR_PERSON_MILITARY_MODIFY_STATUS:
      return {
        ...state,
        personMilitaryStatus: {
          ...state.personMilitaryStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
};

export default personMilitary;
