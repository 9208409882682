const retryCallIfNeeded = async (
  clientInstance,
  request,
  maxRetryAttempts = 2,
  timeout = 0,
  ignoredStatusCodes = [404, 409],
) => {
  try {
    const response = await request();
    if (response.status >= 300) {
      throw response;
    }
    return response;
  } catch (error) {
    const isPersonPut = request.toString().includes('person') && request.toString().includes('put');
    const isPersonNamesPost = request.toString().includes('person') && request.toString().includes('names')
      && request.toString().includes('post');
    if (maxRetryAttempts <= 0
      || !error.status
      || (ignoredStatusCodes.includes(error.status) && !isPersonPut
            && !isPersonNamesPost)) { throw error; }
    if (timeout) {
      await new Promise(resolve => { setTimeout(resolve, timeout); });
    }
    return retryCallIfNeeded(
      clientInstance,
      request,
      maxRetryAttempts - 1,
      timeout,
      ignoredStatusCodes,
    );
  }
};
export default retryCallIfNeeded;
