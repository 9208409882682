const ACTIONS = {
  MULTISTEP_CREATE_ACCOUNT_START: 'MULTISTEP_CREATE_ACCOUNT_START',
  MULTISTEP_CREATE_ACCOUNT_COMPLETION: 'MULTISTEP_CREATE_ACCOUNT_COMPLETION',
  MULTISTEP_CREATE_ACCOUNT_ERROR: 'MULTISTEP_CREATE_ACCOUNT_ERROR',
  REQUEST_PERSON: 'REQUEST_PERSON',
  RECEIVE_PERSON: 'RECEIVE_PERSON',
  REQUEST_PERSON_MISSING: 'REQUEST_PERSON_MISSING',
  REQUEST_PERSON_ERROR: 'REQUEST_PERSON_ERROR',
  REQUEST_POST_PERSON: 'REQUEST_POST_PERSON',
  RECEIVE_POST_PERSON: 'RECEIVE_POST_PERSON',
  REQUEST_POST_PERSON_ERROR: 'REQUEST_POST_PERSON_ERROR',
  REQUEST_UPDATE_PERSON: 'REQUEST_UPDATE_PERSON',
  RECEIVE_UPDATE_PERSON: 'RECEIVE_UPDATE_PERSON',
  REQUEST_UPDATE_PERSON_ERROR: 'REQUEST_UPDATE_PERSON_ERROR',
};

export default ACTIONS;
