import STATUS from '../../globalStatuses';
import ACTIONS from './types';

const cas = (
  state = { cas: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_USER_SSO:
      return {
        ...state,
        casStatus: {
          ...state.casStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.REQUEST_USER_SSO_FINISHED:
      return {
        ...state,
        casStatus: {
          ...state.casStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_USER_SSO_ERROR:
      return {
        ...state,
        casStatus: {
          ...state.casStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
};

export default cas;
