/* eslint-disable no-underscore-dangle */
import { checkSkipMaintenancePage } from '../helpers/commonHelpers';
import FlagNames from './featureFlags';

export default {
  [FlagNames.INDIANA_CONSENT_CHECK_ENABLED]: window.__ENV?.REACT_APP_INDIANA_CONSENT_CHECK_ENABLED === 'true',
  [FlagNames.MAINTENANCE_PAGE_ENABLED]: (window.__ENV || {}).REACT_APP_FEATURE_FLAG_MAINTENANCE_PAGE_ENABLED === 'true'
  && !checkSkipMaintenancePage(),
  [FlagNames.FORGE_ROCK_ENABLED]: (window.__ENV || {}).REACT_APP_FEATURE_FLAG_FORGE_ROCK_ENABLED === 'true',
  [FlagNames.CONTACT_V2_ENABLED]:
  (window.__ENV || {}).REACT_APP_FEATURE_FLAG_CONTACT_V2_ENABLED === 'true',
  [FlagNames.FORGEROCK_SSO_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_FORGEROCK_SSO_ENABLED === 'true',
};
