import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const provinceReductions = {
  [ACTIONS.REQUEST_PROVINCE]: state => ({
    ...state,
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_PROVINCE]: (state, action) => ({
    ...state,
    provincesCanada: action.payload.provincesCanada,
    provincesMexico: action.payload.provincesMexico,
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_PROVINCE_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    provincesStatus: {
      ...state.provincesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default provinceReductions;
