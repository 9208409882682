import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const countryReductions = {
  [ACTIONS.REQUEST_COUNTRY]: state => ({
    ...state,
    countriesStatus: {
      ...state.countriesStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_COUNTRY]: (state, action) => ({
    ...state,
    countries: action.payload.countries,
    countriesStatus: {
      ...state.countriesStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_COUNTRY_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    countriesStatus: {
      ...state.countriesStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default countryReductions;
