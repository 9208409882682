import React, { Suspense } from 'react';
import url from 'url';
import { isIE, isEdge, isEdgeChromium } from 'react-device-detect';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from './Theme';
import CleanSuspensePage from './components/suspense/CleanSuspensePage';
import { history } from './store/configureStore';
import ScrollToTop from './helpers/scrollToTop';
// Yup extensions init
import './extensions/yupMethods';

import NotSupportedBrowser from './components/unsupported-browser/NotSupportedBrowser';
import { clearSkipMaintenanceFlag } from './helpers/commonHelpers';

const CallbackComponent = React.lazy(() => import('./components/auth/CallbackComponentWrapper'));
const NonDegreeUnauthedContainer = React.lazy(() => import('./store/containers/routing-wrappers/non-degree/NonDegreeUnauthedContainer'));
const NonDegreeAuthedContainer = React.lazy(() => import('./store/containers/routing-wrappers/non-degree/NonDegreeAuthedContainer'));

const suspenseContent = <CleanSuspensePage />;

const setSessionValuesFromQueryString = () => {
  const { query } = url.parse(window.location.href, true);
  if (query?.synthetic) {
    sessionStorage.setItem('synthetic', query.synthetic);
  }
};

const isUnsupportedBrowser = () => {
  let isUnsupported = false;

  if (isIE) {
    isUnsupported = true;
  }

  if (isEdge && !isEdgeChromium) {
    isUnsupported = true;
  }

  return isUnsupported;
};
const {
  REACT_APP_MAINTENANCE_PAGE_URL,
  REACT_APP_FEATURE_FLAG_MAINTENANCE_PAGE_ENABLED,
  // eslint-disable-next-line no-underscore-dangle
} = window.__ENV || {};

// clear session storage based on query param skipMaintenance
const isMaintenanceOn = clearSkipMaintenanceFlag();

// Only run once when page is loaded as brower version won't change.
const isUserBrowserUnsupported = isUnsupportedBrowser();

const App = () => {
  setSessionValuesFromQueryString();

  // isMaintenanceOn should NOT be true for real users.
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      {isUserBrowserUnsupported ? <NotSupportedBrowser /> : (
        <Suspense fallback={suspenseContent}>
          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route path="/oidc/callback" component={CallbackComponent} />
                {isMaintenanceOn && REACT_APP_FEATURE_FLAG_MAINTENANCE_PAGE_ENABLED === 'true'
                  ? window.location.replace(REACT_APP_MAINTENANCE_PAGE_URL)
                  : <Route exact path="/courses/personal-information" render={() => <NonDegreeUnauthedContainer />} />}
                <Route path="/courses" render={() => <NonDegreeAuthedContainer />} />
                <Route path="/authdepot/callback" render={() => <NonDegreeAuthedContainer />} />

                <Redirect to="/courses/personal-information" />
              </Switch>
            </ScrollToTop>
          </Router>
        </Suspense>
      )}
      {/*  */}
    </ThemeProvider>
  );
};

export default App;
