import retryCallIfNeeded from '../helpers/restCalls';

const baseEndpointUrl = '/cas/legacy';

export default function casService(client) {
  const postLegacy = credentials => retryCallIfNeeded(
    client,
    () => client.post(`${baseEndpointUrl}`, credentials),
    60,
    500,
  );

  return {
    postLegacy,
  };
}
