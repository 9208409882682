import { createTheme } from '@material-ui/core/styles';

// reference this palette in your component within makeStyles with theme.palette
export default createTheme({
  palette: {
    common: {
      black: '#212121',
      white: '#FFF',
      warning: '#F6B563',
    },
    background: {
      paper: '#FFF',
      dark: '#666666',
      default: '#FFFFFF',
      mainBtn: '#db3725',
      hoverBtn: '#8a1500',
      disabledBtn: '#d6d7d9',
      disabledInput: '#f5f5f5',
    },
    primary: {
      light: '#CD2026',
      main: '#DB3725',
      contrastText: '#FFF',
      dark: '#8A1500',
    },
    secondary: {
      main: '#009CAD',
      dark: '#018391',
      contrastText: '#FFF',
    },
    error: {
      light: '#CD2026',
      main: '#DB3725',
      contrastText: '#FFF',
    },
    text: {
      primary: '#001823',
      secondary: '#001823',
      disabled: '#001823',
      hint: '#667982',
    },
  },
  typography: {
    // this along with giving html font-size 62.5% in index.css allows
    // us to have 1rem == 10px for easier calculation
    // for example, 24px will be equal to 2.4rem, see: https://material-ui.com/customization/typography/#font-size
    htmlFontSize: 10,
    button: {
      borderRadius: 30,
      textTransform: 'initial',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
      },
    },
    MuiTooltip: {
      arrow: {
        color: 'black',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  // werkt uses a 10px (1rem) grid in their design software so, best to align with them
  spacing: factor => `${1 * factor}rem`,
});
