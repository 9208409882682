import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const contactPhone = (
  state = { contactPhone: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_CONTACT_PHONE:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CONTACT_PHONE:
      return {
        ...state,
        phone: action.phone,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_CONTACT_PHONE_ERROR:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_CONTACT_PHONE_MISSING:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          getStatus: STATUS.MISSING,
        },
      };
    case ACTIONS.REQUEST_POST_PHONE:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PHONE:
      return {
        ...state,
        phone: action.phone,
        phoneETag: action.phoneETag,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PHONE_POST_ERROR:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

      case ACTIONS.REQUEST_UPDATE_PHONE:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_PHONE:
      return {
        ...state,
        phone: action.phone,
        phoneETag: action.phoneETag,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PHONE_UPDATE_ERROR:
      return {
        ...state,
        contactPhoneStatus: {
          ...state.contactPhoneStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
};

export default contactPhone;
