const ACTIONS = {
  REQUEST_CONTACT_EMAIL: 'REQUEST_CONTACT_EMAIL',
  RECEIVE_CONTACT_EMAIL: 'RECEIVE_CONTACT_EMAIL',
  REQUEST_CONTACT_EMAIL_MISSING: 'REQUEST_CONTACT_EMAIL_MISSING',
  REQUEST_CONTACT_EMAIL_ERROR: 'REQUEST_CONTACT_EMAIL_ERROR',
  REQUEST_POST_EMAIL: 'REQUEST_POST_EMAIL',
  RECEIVE_POST_EMAIL: 'RECEIVE_POST_EMAIL',
  REQUEST_EMAIL_POST_ERROR: 'REQUEST_EMAIL_POST_ERROR',
  REQUEST_UPDATE_EMAIL: 'REQUEST_UPDATE_EMAIL',
  RECEIVE_UPDATE_EMAIL: 'RECEIVE_UPDATE_EMAIL',
  REQUEST_UPDATE_EMAIL_ERROR: 'REQUEST_UPDATE_EMAIL_ERROR',
};

export default ACTIONS;
