import ACTIONS from '../types';
import STATUS from '../../../globalStatuses';

const outlyingAreaReductions = {
  [ACTIONS.REQUEST_OUTLYING_AREA]: state => ({
    ...state,
    outlyingAreasStatus: {
      ...state.outlyingAreasStatus,
      getStatus: STATUS.FETCHING,
      errorData: null,
    },
  }),
  [ACTIONS.RECEIVE_OUTLYING_AREA]: (state, action) => ({
    ...state,
    outlyingAreas: action.payload.outlyingAreas,
    outlyingAreasStatus: {
      ...state.outlyingAreasStatus,
      getStatus: STATUS.FETCHED,
    },
  }),
  [ACTIONS.RECEIVE_OUTLYING_AREA_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    outlyingAreasStatus: {
      ...state.outlyingAreasStatus,
      getStatus: STATUS.ERROR,
      errorData: action.error,
    },
  }),
};

export default outlyingAreaReductions;
