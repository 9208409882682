import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpoint = '/api/contacts';

export default function consentService(client) {
  const postPhoneConsentAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/v2/consent/phone`, payload));
  const getPhoneConsentAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/v2/consent/phone/${personId}`));
  const postEmailConsentAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/consent/v1/email`, payload));

  return {
    postPhoneConsentAsync,
    getPhoneConsentAsync,
    postEmailConsentAsync,
  };
}
