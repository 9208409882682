import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UniversityOfPhoenix = ({ ...rest }) => (
  <SvgIcon color="primary" viewBox="0 0 66 76" {...rest}>
    <path d="M62.15828,35.18118c-.06566.03283-.23093.1-.33949.139a15.8666,15.8666,0,0,1-5.26321.89089l-18.39365.003a2.57507,2.57507,0,0,1-1.55084-.32606c-.06677-.05745-.10632-.06342-.05969.06174.19176.54785,1.70753,5.03285,1.70753,5.03285a1.7064,1.7064,0,0,0,1.82989.94964L54.81336,41.926a7.50827,7.50827,0,0,0,7.39379-6.20315c.01977-.11472.03731-.23.05186-.34695C62.30415,35.05284,62.28363,35.12913,62.15828,35.18118Z" />
    <path d="M59.67142,42.17971a15.84293,15.84293,0,0,1-5.53182.98862s-12.96777.019-13.56766,0a3.07707,3.07707,0,0,1-1.51129-.28577c-.06566-.03282-.21862-.15967-.14737.00616.06081.20313.93864,2.65642.93864,2.65642a4.92987,4.92987,0,0,0,4.65624,3.33278l7.88549.00765a7.50991,7.50991,0,0,0,7.45722-6.63647C59.8822,42.04055,59.87362,42.11162,59.67142,42.17971Z" />
    <path d="M23.41127,45.26086c.24063-1.023.63571-3.4434.651-3.51988.016-.094.009-.12759-.075-.04514a5.0701,5.0701,0,0,1-6.73422.16583L2.23194,30.0435A6.718,6.718,0,0,0,1.32912,33.403,5.92575,5.92575,0,0,0,3.528,38.19891l12.065,9.71258A5.09334,5.09334,0,0,0,23.41127,45.26086Z" />
    <path d="M63.32262,28.42141a16.11229,16.11229,0,0,1-5.53443.84425s-15.93664.02238-19.09874-.00224c-2.60923-.02052-3.12966-3.40983-3.17554-4.21434-.391-4.87243,2.05708-7.02614,2.05708-7.02614a3.49331,3.49331,0,0,1,2.91924-1.29267c.016.00149.11976.01063.0291-.05354-.35627-.25368-1.62992-1.15744-2.22944-1.59392a1.795,1.795,0,0,0-1.89256-.27346l-3.61016,1.493a1.15717,1.15717,0,0,1-.92371-.21545.81189.81189,0,0,0-.78829-.03451s-3.74036,1.91551-5.04348,2.4878a2.41837,2.41837,0,0,1-2.86142-.73588c-.11863-.116-.09625-.19474-.16974.02313a3.62069,3.62069,0,0,0-.23541,1.29118,3.30229,3.30229,0,0,0,1.29977,2.84146,4.064,4.064,0,0,0,3.5389.07946c.18131-.05894.12162,0,.0429.08637a13.76145,13.76145,0,0,0-2.36,4.4061c-.40217,1.06808-.93752,1.48069-1.68775,1.58459a2.70147,2.70147,0,0,1-2.32868-.67226c-.78046-.60437-8.09143-6.46617-8.5932-6.88027-.14214.15669-4.29549,3.78438-4.29549,3.78438l11.71651,9.24942a4.098,4.098,0,0,0,2.92,1.09477c2.70473-.427,3.02892-2.93995,3.50235-4.89052q.202-.8047.42939-1.54114c1.7605-5.70493,4.673-8.43036,8.18433-10.05693.20556-.09476.08207.02593.03917.07331-1.29976,1.405-1.97725,3.69634-1.74035,6.66557a48.09478,48.09478,0,0,0,2.23765,8.7137,1.8051,1.8051,0,0,0,1.84369,1.31954l18.51564.00149a7.38271,7.38271,0,0,0,7.46243-6.49583C63.50841,28.31527,63.536,28.33206,63.32262,28.42141Z" />
    <path d="M.1286,23.88194a6.748,6.748,0,0,1,2.25556-3.02556C2.81579,20.466,16.94753,7.98914,16.94753,7.98914A7.72986,7.72986,0,0,0,18.93859.20288c-.0832-.29957-.08656-.22309-.17721-.05428A16.68892,16.68892,0,0,1,15.55,3.69832L1.6134,16.24994A5.02676,5.02676,0,0,0,.00325,20.26432S.001,23.36543.00026,23.89351C-.00571,24.15241.09129,24.07146.1286,23.88194Z" />
    <path d="M35.01288,44.8656c-3.23784-2.01624-4.41449-4.35648-4.45515-6.42327-.0015-.2507.05969-.42249-.23056.08861a7.77163,7.77163,0,0,0,.06641,9.00954c1.28036,2.014,4.33987,4.06922,5.08526,4.59655,1.79034,1.26674,5.07705,3.26619,6.21565,6.696.07573.22925.18318.86943.25742.19866C42.86032,50.5722,38.59543,47.07527,35.01288,44.8656Z" />
    <path d="M37.499,55.38774c-1.28036-1.17292-6.36338-4.65008-7.79932-6.20334-1.06808-1.1567-2.72972-2.571-2.69391-5.04721-.00671-.18262.02537-.25667-.116-.10707-1.56576,1.63813-1.75527,6.41113-.15893,8.90415A21.33151,21.33151,0,0,0,31.862,58.22453c1.0278.83362,4.83643,3.03172,5.66389,5.93958a2.64184,2.64184,0,0,1-.407,2.60233,8.05338,8.05338,0,0,0-3.57994-5.7012c-1.731-1.25686-3.994-2.91719-5.31918-4.05933-1.66537-1.43873-4.80957-4.3-4.426-7.71911.00559-.06193.0276-.16284-.03656-.09009-5.47512,5.48351-5.53556,14.85362,1.03078,18.8305.17347.11379.078-.03749.03283-.11621a7.18349,7.18349,0,0,1-1.21321-3.51223,7.04018,7.04018,0,0,1,1.402-4.50029,10.78635,10.78635,0,0,0,4.49694,4.87392c2.17311,1.51744,3.53778,2.76274,3.62172,4.98379a4.01767,4.01767,0,0,1-2.46783,4.12275c-.1399.06342-.18653.14139.0679.11621,5.65456-.64111,7.62062-3.47567,8.79727-5.82655A10.49515,10.49515,0,0,0,37.499,55.38774Z" />
    <path d="M18.18537,40.94878c2.80023,1.93005,6.16566.04663,6.76032-3.396.18952-.83828.52565-2.52976.53871-2.59636.01754-.0886-.01306-.07013-.06267-.02816-2.0653,1.645-3.81125,1.32643-5.87654-.09327l-12.162-9.6238s-3.856,3.53667-4.222,3.895Z" />
    <path d="M4.8255,25.77227c1.71461-1.55718,10.3481-9.047,10.3481-9.047,1.87279-1.6954,3.76685-4.66594,2.97968-8.12706-.04962-.23689-.075-.12759-.14214-.03973a24.38808,24.38808,0,0,1-3.2744,3.1373C11.35565,14.65788,5.98163,19.34285,3.062,21.994A15.42509,15.42509,0,0,0,.90681,24.16939a5.036,5.036,0,0,0-.90095,3.222L.0081,31.34363c-.00224.29808.09252.19027.15445-.04812.30778-1.39975,1.62657-2.60624,2.84164-3.83922C3.00419,27.45629,3.11014,27.32944,4.8255,25.77227Z" />
    <path d="M44.54733,65.13073a2.13766,2.13766,0,1,0,2.172,2.1317A2.13561,2.13561,0,0,0,44.54733,65.13073Zm0,3.93864a1.80151,1.80151,0,1,1,1.77094-1.80694A1.78768,1.78768,0,0,1,44.54733,69.06937Z" />
    <path d="M45.45574,66.654c0-.48368-.41335-.60139-.82671-.60139h-.93379v2.38688h.4018V67.39878h.5096a.3509.3509,0,0,1,.31636.20015.94945.94945,0,0,1,.08394.437l-.00149.40347h.40217V68.036a2.03967,2.03967,0,0,0-.02313-.28652.49837.49837,0,0,0-.41336-.49188v-.02313A.574.574,0,0,0,45.45574,66.654Zm-.8491.42622H44.097v-.72206h.5096c.33017,0,.42493.15519.42493.35534C45.03157,66.9151,44.92375,67.08018,44.60664,67.08018Z" />
  </SvgIcon>
);

export default UniversityOfPhoenix;
