const ACTIONS = {
  REQUEST_SSN: 'REQUEST_SSN',
  RECEIVE_SSN: 'RECEIVE_SSN',
  REQUEST_SSN_MISSING: 'REQUEST_SSN_MISSING',
  REQUEST_SSN_ERROR: 'REQUEST_SSN_ERROR',

  REQUEST_POST_SSN: 'REQUEST_POST_SSN',
  RECEIVE_POST_SSN: 'RECEIVE_POST_SSN',
  REQUEST_POST_SSN_ERROR: 'REQUEST_POST_SSN_ERROR',

  REQUEST_PUT_SSN: 'REQUEST_PUT_SSN',
  RECEIVE_PUT_SSN: 'RECEIVE_PUT_SSN',
  REQUEST_PUT_SSN_ERROR: 'REQUEST_PUT_SSN_ERROR',

  REQUEST_DELETE_SSN: 'REQUEST_DELETE_SSN',
  RECEIVE_DELETE_SSN: 'RECEIVE_DELETE_SSN',
  REQUEST_DELETE_SSN_ERROR: 'REQUEST_DELETE_SSN_ERROR',

  CLEAR_SSN_MODIFY_STATUS: 'CLEAR_SSN_MODIFY_STATUS',
};

export default ACTIONS;
