import store from '../../store/configureStore'; // TODO: Fix
import { invalidateTokenIfNeeded, fetchTokenIfNeeded } from '../../store/domains/csrf/actions';

export default async (response, requestConfig, client) => {
  const invalidCsrfTokenHeaderName = 'x-ctknbad';
  if (response.status === 403 && response.headers.get(invalidCsrfTokenHeaderName)) {
    await store.dispatch(invalidateTokenIfNeeded());
    await store.dispatch(fetchTokenIfNeeded());
    return client.request(requestConfig);
  }
  return null;
};
