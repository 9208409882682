import Cookies from 'js-cookie';

const requestingIP = Cookies.get('remote-address') || 'Unknown';
// const requestingUrl = TODO: add implementation
// const referringUrl = TODO: add implementation
const mcid = Cookies.get('s_ecid') || '';
Cookies.get();
const cookie = document.cookie.match('(^|;) *AMCV_8DF667C25245B0070A490D4C%40AdobeOrg=([^;]*)');
let value;
let amcMcid;
if (cookie) {
  value = decodeURI(cookie[2]);
  value = value.substring(value.lastIndexOf('|MCMID|') + 7);
  amcMcid = value.substring(0, value.indexOf('|'));
}
sessionStorage.setItem('mcId', mcid);
sessionStorage.setItem('amcMcid', amcMcid);
export default {
  requestingIP,
  mcid,
  amcMcid,
  // requestingUrl,
  // referringUrl,
};
