import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Header from '../header/Header';
import styles from './styles';

import LoadingPage from '../loading-page/LoadingPage';

function CleanSuspensePage({
  suppressHeader,
}) {
  const classes = styles();

  return (
    <>
      {!suppressHeader && <Header />}
      <main role="main">
        {/* Page Wrapper */}
        <Container maxWidth="lg" className={classes.root}>
          <LoadingPage
            hide={false}
          />
        </Container>
      </main>
    </>
  );
}

CleanSuspensePage.defaultProps = {
  suppressHeader: false,
};

CleanSuspensePage.propTypes = {
  suppressHeader: PropTypes.bool,
};

export default CleanSuspensePage;
