import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

function LoadingPage({ hide }) {
  const classes = styles();

  return (
    <div className={`${classes.loadingBox} ${hide ? classes.hide : ''}`}>
      <span className={classes.screenReaderText} role="alert" aria-busy="true" id="loading-page-title">Loading</span>
      <CircularProgress size={150} />
    </div>
  );
}

LoadingPage.defaultProps = {
  hide: false,
};
LoadingPage.propTypes = {
  hide: PropTypes.bool,
};

export default LoadingPage;
