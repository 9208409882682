import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personAssociation = (
  state = { personAssociations: {} },
  action,
) => {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: action.associations,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_ASSOCIATIONS_MISSING:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          getStatus: STATUS.MISSING,
        },
      };

    // POST
    case ACTIONS.REQUEST_POST_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: [...state.associations, action.association],
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT
    case ACTIONS.REQUEST_PUT_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PUT_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: state.associations.map(association => {
          if ((association?.externalSystemIds?.PROFILE_ASSOCIATION_ID || [''])[0] === action.associationId) {
            return action.association;
          }
          return association;
        }),
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PUT_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE
    case ACTIONS.REQUEST_DELETE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATING,
        },
      };
    case ACTIONS.RECEIVE_DELETE_PERSON_ASSOCIATIONS:
      return {
        ...state,
        associations: state.associations
          .filter(association => association.id !== action.associationId),
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_PERSON_ASSOCIATIONS_ERROR:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // Clear
    case ACTIONS.CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS:
      return {
        ...state,
        personAssociationStatus: {
          ...state.personAssociationStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    default:
      return state;
  }
};

export default personAssociation;
