import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const contactEmail = (
  state = { contactEmail: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_CONTACT_EMAIL:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CONTACT_EMAIL:
      return {
        ...state,
        email: action.email,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_CONTACT_EMAIL_ERROR:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_CONTACT_EMAIL_MISSING:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          getStatus: STATUS.MISSING,
        },
      };
    case ACTIONS.REQUEST_POST_EMAIL:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_EMAIL:
      return {
        ...state,
        email: action.email,
        emailETag: action.emailETag,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_EMAIL_POST_ERROR:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
      case ACTIONS.REQUEST_UPDATE_EMAIL:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_EMAIL:
      return {
        ...state,
        email: action.email,
        emailETag: action.emailETag,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_UPDATE_EMAIL_ERROR:
      return {
        ...state,
        contactEmailStatus: {
          ...state.contactEmailStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
};

export default contactEmail;
