import retryCallIfNeeded from '../../helpers/restCalls';

const baseSagasEndpoint = '/api/sagas/v1';

const defaultHeaders = {
  headers: {
    'out-of-the-blue': 1,
  },
};

export default function SagasService(client) {
  const startWorkFlowAsync = async (personId, workFlowName, workFlowVersion) => {
    const url = `${baseSagasEndpoint}/workflow`;
    const sagasWorkflowPayload = {
      name: workFlowName,
      version: workFlowVersion,
      personId,
      input: {
        personId,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, sagasWorkflowPayload, defaultHeaders));
  };

  const getWorkflowByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseSagasEndpoint}/workflow/NAP/person/${personId}?includeTasks=true`, defaultHeaders));

  const completeTaskAsync = async ({
    workflowInstanceId,
    personId,
    mcid,
    taskName,
    appContext,
    program,
    campusOrgaNumber,
    campaign,
    source,
    anticipatedStartDate,
    ownerNTLogin,
    synthetic,
    disclaimer,
    requestingIP,
    requestingUrl,
    referringUrl,
    userAgent,
    isMobileDevice,
    stateConsentDisclaimer,
    forgerockEnabled,
  }) => {
    const url = `${baseSagasEndpoint}/tasks`;
    const sagasTaskPayload = {
      workflowInstanceId,
      taskName,
      status: 'COMPLETED',
      outputData: {
        personId,
        mcid,
        appContext,
        program,
        campusOrgaNumber,
        campaign,
        source,
        requestingIP,
        anticipatedStartDate,
        disclaimer,
        ownerNTLogin,
        synthetic,
        requestingUrl,
        referringUrl,
        userAgent,
        isMobileDevice,
        stateConsentDisclaimer,
        forgerockEnabled,
      },
    };
    return retryCallIfNeeded(client, () => client.post(url, sagasTaskPayload, defaultHeaders));
  };

  const getPersonTasksAsync = async personId => {
    const url = `${baseSagasEndpoint}/persontasks/${personId}`;
    return retryCallIfNeeded(client, () => client.get(url));
  };

  const completePersonTaskAsync = async (personId, taskId) => {
    const url = `${baseSagasEndpoint}/persontask/${personId}/${taskId}`;
    const payload = {
      feedback: {},
      status: 'COMPLETED',
    };
    return retryCallIfNeeded(client, () => client.put(url, payload));
  };

  return {
    startWorkFlowAsync,
    getWorkflowByPersonIdAsync,
    completeTaskAsync,
    getPersonTasksAsync,
    completePersonTaskAsync,
  };
}
