const ACTIONS = {
  REQUEST_CONTACT_ADDRESS: 'REQUEST_CONTACT_ADDRESS',
  RECEIVE_CONTACT_ADDRESS: 'RECEIVE_CONTACT_ADDRESS',
  REQUEST_CONTACT_ADDRESS_MISSING: 'REQUEST_CONTACT_ADDRESS_MISSING',
  REQUEST_CONTACT_ADDRESS_ERROR: 'REQUEST_CONTACT_ADDRESS_ERROR',

  REQUEST_POST_ADDRESS: 'REQUEST_POST_ADDRESS',
  RECEIVE_POST_ADDRESS: 'RECEIVE_POST_ADDRESS',
  REQUEST_ADDRESS_POST_ERROR: 'REQUEST_ADDRESS_POST_ERROR',

  REQUEST_UPDATE_CONTACT_ADDRESS: 'REQUEST_UPDATE_CONTACT_ADDRESS',
  RECEIVE_UPDATE_CONTACT_ADDRESS: 'RECEIVE_UPDATE_CONTACT_ADDRESS',
  REQUEST_UPDATE_CONTACT_ADDRESS_ERROR: 'REQUEST_UPDATE_CONTACT_ADDRESS_ERROR',
};

export default ACTIONS;
