import COUNTRY_ACTIONS from './types/countryTypes';
import STATE_ACTIONS from './types/stateTypes';
import PROVINCE_ACTIONS from './types/provinceTypes';
import MILITARY_COUNTRY_ACTIONS from './types/militaryCountryTypes';
import MILITARY_STATE_ACTIONS from './types/militaryStateTypes';
import MILITARY_CITY_ACTIONS from './types/militaryCityTypes';
import DISTRICT_ACTIONS from './types/districtTypes';
import OUTLYING_AREA_ACTIONS from './types/outlyingAreaTypes';

const ACTIONS = {
  ...COUNTRY_ACTIONS,
  ...STATE_ACTIONS,
  ...PROVINCE_ACTIONS,
  ...MILITARY_COUNTRY_ACTIONS,
  ...MILITARY_STATE_ACTIONS,
  ...MILITARY_CITY_ACTIONS,
  ...DISTRICT_ACTIONS,
  ...OUTLYING_AREA_ACTIONS,
};

export default ACTIONS;
