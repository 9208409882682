import retryCallIfNeeded from '../../helpers/restCalls';

const {
  REACT_APP_FEATURE_FLAG_CONTACT_V2_ENABLED,
  // eslint-disable-next-line no-underscore-dangle
} = window.__ENV || {};

const baseEndpointUrl = '/api/contacts/v1';
const baseEndpointUrlV2 = '/api/contacts/v2';
let url;

export default function addressService(client) {
  const getContactAddressAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/addresses?linkId=${personId}&linkType=person`));
  const getFormattedAddressSuggestionAsync = async input => {
    if (input?.length > 1) {
      const encodedInput = encodeURI(input);
      if (REACT_APP_FEATURE_FLAG_CONTACT_V2_ENABLED === 'true') {
        url = `${baseEndpointUrlV2}/suggest/address?prefix=${encodedInput}`;
      } else {
        url = `${baseEndpointUrl}/suggest/address?prefix=${encodedInput}`;
      }
      let response;
      try{
       response = await client.get(url);
      } catch(e) {        
      }            
      return Array.isArray(response?.data?.suggestions)
        ? response.data.suggestions.map(suggestion => (
          { label: suggestion.text, value: suggestion }
        ))
        : [];
    }
    return [];
  };
  const getAddressValidationAsync = (street, city, state) => retryCallIfNeeded(client, async () => {
    const response = await client
      .get(`${baseEndpointUrl}/validate/address?street=${encodeURI(street)}&city=${encodeURI(city)}&state=${encodeURI(state)}`);
    return response?.data;
  });
  const getZipCodeValidationAsync = zipCode => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrl}/validate/zipcode?zipcode=${encodeURI(zipCode)}`));
  const postContactAddressAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpointUrl}/address`, payload));
  const putContactAddressAsync = async (addressId, payload) => retryCallIfNeeded(client,
    () => client.put(`${baseEndpointUrl}/address/${addressId}`, payload));
  return {
    getContactAddressAsync,
    getFormattedAddressSuggestionAsync,
    getAddressValidationAsync,
    getZipCodeValidationAsync,
    postContactAddressAsync,
    putContactAddressAsync,
  };
}
