const ACTIONS = {
  REQUEST_PERSON_ASSOCIATIONS: 'REQUEST_PERSON_ASSOCIATIONS ',
  RECEIVE_PERSON_ASSOCIATIONS: 'RECEIVE_PERSON_ASSOCIATIONS',
  REQUEST_PERSON_ASSOCIATIONS_MISSING: 'REQUEST_PERSON_ASSOCIATIONS_MISSING',
  REQUEST_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_PERSON_ASSOCIATIONS_ERROR',

  REQUEST_POST_PERSON_ASSOCIATIONS: 'REQUEST_POST_PERSON_ASSOCIATIONS ',
  RECEIVE_POST_PERSON_ASSOCIATIONS: 'RECEIVE_POST_PERSON_ASSOCIATIONS',
  REQUEST_POST_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_POST_PERSON_ASSOCIATIONS_ERROR',

  REQUEST_PUT_PERSON_ASSOCIATIONS: 'REQUEST_PUT_PERSON_ASSOCIATIONS ',
  RECEIVE_PUT_PERSON_ASSOCIATIONS: 'RECEIVE_PUT_PERSON_ASSOCIATIONS',
  REQUEST_PUT_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_PUT_PERSON_ASSOCIATIONS_ERROR',

  REQUEST_DELETE_PERSON_ASSOCIATIONS: 'REQUEST_DELETE_PERSON_ASSOCIATIONS ',
  RECEIVE_DELETE_PERSON_ASSOCIATIONS: 'RECEIVE_DELETE_PERSON_ASSOCIATIONS',
  REQUEST_DELETE_PERSON_ASSOCIATIONS_ERROR: 'REQUEST_DELETE_PERSON_ASSOCIATIONS_ERROR',

  CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS: 'CLEAR_PERSON_ASSOCIATIONS_MODIFY_STATUS',
};

export default ACTIONS;
