/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import './style.scss';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import store from './store/configureStore';
import userManager from './store/domains/oauth/userManager';
import App from './App';
import 'modality-polyfill/src/keyboard-modality';

const { REACT_APP_ADOBE_LAUNCH_SCRIPT_URL } = window.__ENV || {};
const AEC_LAUNCH_URL_FLAG_CHECKED = [REACT_APP_ADOBE_LAUNCH_SCRIPT_URL];

/* workaround for  launch page-view not firing when adobe js files are cached */
const onLaunchScriptLoaded = (newState, addedTags) => {
  if (addedTags && addedTags.scriptTags) {
    const foundScript = addedTags.scriptTags
      .find(({ src }) => src === REACT_APP_ADOBE_LAUNCH_SCRIPT_URL);
    if (foundScript) {
      document.getElementById(foundScript.id).addEventListener('load', () => {
        const uopxDataLayer = new CustomEvent('uopxDataLayer', {
          detail: {
            loaded: true,
          },
        });
        const intervalId = setInterval(() => {
          if (window.uopxDataLayer) {
            window.dispatchEvent(uopxDataLayer);
            clearInterval(intervalId);
          }
        }, 100);
      }, { once: true });
    }
  }
};

const urlIncludesCallback = window.location.pathname.toLowerCase().includes('callback');
const urlIncludesOIDC = window.location.pathname.toLowerCase().includes('oidc');

render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <React.StrictMode>
        {/* This script is also injected with AdobeLaunch.jsx */}
        {((urlIncludesCallback && urlIncludesOIDC)
          || (!urlIncludesCallback && !urlIncludesOIDC)) && (
          <Helmet
            onChangeClientState={onLaunchScriptLoaded}
            script={
            [...AEC_LAUNCH_URL_FLAG_CHECKED].map(script => ({
              src: script,
              id: 'adobe_launch_script',
              type: 'text/javascript',
              defer: true,
              async: true,
            }))
          }
          />
        )}
        <App />
      </React.StrictMode>
    </OidcProvider>
  </Provider>,
  document.getElementById('root'),
);
