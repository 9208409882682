const ACTIONS = {
  REQUEST_PERSON_CITIZENSHIP: 'REQUEST_PERSON_CITIZENSHIP',
  RECEIVE_PERSON_CITIZENSHIP: 'RECEIVE_PERSON_CITIZENSHIP',
  REQUEST_PERSON_CITIZENSHIP_MISSING: 'REQUEST_PERSON_CITIZENSHIP_MISSING',
  REQUEST_PERSON_CITIZENSHIP_ERROR: 'REQUEST_PERSON_CITIZENSHIP_ERROR',

  REQUEST_PUT_PERSON_CITIZENSHIP: 'REQUEST_PUT_PERSON_CITIZENSHIP',
  RECEIVE_PUT_PERSON_CITIZENSHIP: 'RECEIVE_PUT_PERSON_CITIZENSHIP',
  REQUEST_PUT_PERSON_CITIZENSHIP_ERROR: 'REQUEST_PUT_PERSON_CITIZENSHIP_ERROR',

  REQUEST_POST_PERSON_CITIZENSHIP: 'REQUEST_POST_PERSON_CITIZENSHIP',
  RECEIVE_POST_PERSON_CITIZENSHIP: 'RECEIVE_POST_PERSON_CITIZENSHIP',
  REQUEST_POST_PERSON_CITIZENSHIP_ERROR: 'REQUEST_POST_PERSON_CITIZENSHIP_ERROR',

  REQUEST_DELETE_PERSON_CITIZENSHIP: 'REQUEST_DELETE_PERSON_CITIZENSHIP',
  RECEIVE_DELETE_PERSON_CITIZENSHIP: 'RECEIVE_DELETE_PERSON_CITIZENSHIP',
  REQUEST_DELETE_PERSON_CITIZENSHIP_ERROR: 'REQUEST_DELETE_PERSON_CITIZENSHIP_ERROR',
  CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS: 'CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS',
};

export default ACTIONS;
