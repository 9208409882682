import STATUS from '../../globalStatuses';
import ACTIONS from './types';

const wallet = (
  state = { },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_SSN:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SSN:
      return {
        ...state,
        last4Ssn: action.last4Ssn,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_SSN_MISSING:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.MISSING,
        },

      };
    case ACTIONS.REQUEST_SSN_ERROR:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    case ACTIONS.REQUEST_POST_SSN:
    case ACTIONS.REQUEST_DELETE_SSN:
    case ACTIONS.REQUEST_PUT_SSN:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };

    case ACTIONS.RECEIVE_POST_SSN:
    case ACTIONS.RECEIVE_PUT_SSN:
      return {
        ...state,
        personId: action.personId,
        last4Ssn: action.last4Ssn,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.RECEIVE_DELETE_SSN:
      return {
        ...state,
        personId: '',
        last4Ssn: '',
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };

    case ACTIONS.REQUEST_POST_SSN_ERROR:
    case ACTIONS.REQUEST_PUT_SSN_ERROR:
    case ACTIONS.REQUEST_DELETE_SSN_ERROR:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    case ACTIONS.CLEAR_SSN_MODIFY_STATUS:
      return {
        ...state,
        ssnStatus: {
          ...state.ssnStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
};

export default wallet;
