import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
    marginBottom: theme.spacing(10),
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(70),
    },
  },
  hide: {
    display: 'none',
  },
}));