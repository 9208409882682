import STATUS from '../../globalStatuses';
import ACTIONS from './types';
import { createRequestAction } from '../../../helpers/commonHelpers';
/* action creators */
const requestToken = () => (
  { type: ACTIONS.REQUEST_TOKEN }
);

const receiveToken = () => (
  { type: ACTIONS.RECEIVE_TOKEN, receivedAt: Date.now() }
);

const requestTokenError = error => createRequestAction(
  error, ACTIONS.REQUEST_TOKEN_MISSING,
  ACTIONS.REQUEST_TOKEN_ERROR,
);

const invalidateToken = () => (
  { type: ACTIONS.INVALIDATE_TOKEN, receivedAt: Date.now() }
);

const fetchToken = () => (
  async (dispatch, getState, { Services }) => {
    dispatch(requestToken());
    try {
      await Services.csrfService.getCsrfTokenAsync();
      dispatch(receiveToken());
    } catch (error) {
      dispatch(requestTokenError(error));
    }
  }
);

const shouldFetchToken = state => {
  const { csrf } = state;
  if (!csrf) {
    return true;
  }
  return csrf.status === STATUS.UNFETCHED
  || csrf.status === STATUS.MISSING
  || csrf.status === STATUS.INVALIDATED;
};

export const fetchTokenIfNeeded = () => (async (dispatch, getState) => {
  if (shouldFetchToken(getState())) {
    await dispatch(fetchToken());
  }
});

const shouldInvalidateToken = state => {
  const { csrf } = state;
  if (!csrf) {
    return true;
  }
  return csrf.status !== STATUS.FETCHING;
};

export const invalidateTokenIfNeeded = () => ((dispatch, getState) => {
  if (shouldInvalidateToken(getState())) {
    // Dispatch a thunk from thunk!
    dispatch(invalidateToken());
  }
});
