import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpoint = '/api/persons/v1';

export default function PersonService(client) {
  const getPersonMetaByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/person/${personId}`));
  const postPersonAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/persons`, payload));
  const putPersonAsync = async (personId, payload, etag) => retryCallIfNeeded(client, () => {
    const config = {
      headers: {
        'If-Match': etag || 'Default',
      },
    };
    return client.put(`${baseEndpoint}/person/${personId}`, payload, config);
  }, 5, 5000);

  return {
    getPersonMetaByPersonIdAsync,
    putPersonAsync,
    postPersonAsync,
  };
}
