import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import UOPIcon from '../icon/UniversityOfPhoenix';

import styles from './styles';

function Header({
  children,
}) {
  const classes = styles();

  return (
    <>
      <AppBar
        position="relative"
        className={classes.appBar}
        elevation={1}
        role="banner"
      >
        <Toolbar>
          <Container maxWidth="lg" className={classes.container}>
            <UOPIcon className={classes.icon} />
          </Container>
        </Toolbar>
      </AppBar>
      <div>
        {children}
      </div>
    </>
  );
}

Header.propTypes = {
  children: PropTypes.element,
};

Header.defaultProps = {
  children: <></>,
};

export default Header;
