import Cookies from 'js-cookie';

const csrfCookieName = 'CSRF-Token';

export default config => {
  const csrfToken = Cookies.get(csrfCookieName);
  return {
    ...config,
    headers: { ...config?.headers, [csrfCookieName]: csrfToken },
  };
};
