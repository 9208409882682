import retryCallIfNeeded from '../../helpers/restCalls';

const addressEndpointUrl = '/api/contacts/v1/address';
// const personBaseEndpointUrl = '/api/persons/v1/person/enum';

export default function enumService(client) {
  const getStatesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=State`));
  const getCountriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Country`));
  const getProvincesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Province`));
  const getAdministrativeTerritoriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Administrative Territory`));
  const getOutlyingAreasAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Outlying Area`));
  const getMilitaryCountriesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Country Military`));
  const getMilitaryStatesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Military State`));
  const getMilitaryCitiesAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=Military City`));
  const getDistrictsAsync = async () => retryCallIfNeeded(client, () => client.get(`${addressEndpointUrl}?type=District`));

  // const getMilServiceTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${personBaseEndpointUrl}/MilitaryServiceType`));
  // const getMilBranchTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${personBaseEndpointUrl}/MilitaryServiceCategory`));
  // // TODO: These endpoints don't actually exist?
  // const getMilEducTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/milEducTypes`));
  // const getTestProgTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/testProgTypes`));
  // const getSecInstTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/secInstTypes`));
  // const getDegreeTypesAsync = async () => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/degreeTypes`));

  return {
    getCountriesAsync,
    getStatesAsync,
    getProvincesAsync,
    getAdministrativeTerritoriesAsync,
    getOutlyingAreasAsync,
    getMilitaryCountriesAsync,
    getMilitaryStatesAsync,
    getMilitaryCitiesAsync,
    getDistrictsAsync,

    // // TODO: These endpoints don't actually exist?
    // getMilServiceTypesAsync,
    // getMilBranchTypesAsync,
    // getMilEducTypesAsync,
    // getSecInstTypesAsync,
    // getTestProgTypesAsync,
    // getDegreeTypesAsync,
  };
}
