/* eslint-disable no-template-curly-in-string */
// https://github.com/jquense/yup#extending-schema-types
// Test exclusive is false by default
import * as yup from 'yup';

const extensionMethodName = 'notContains';

function notContainsHandler(arrayOfYupReferences, customMessage, { splitWords }) {
  return this.test({
    name: extensionMethodName,
    message: customMessage || '${path} cannot contain ${reference}',
    params: {
      reference: arrayOfYupReferences.map(reference => reference.path),
    },
    test(value) {
      const { resolve } = this;
      const nullCheckedAndLoweredValue = (value || '').toLowerCase();
      const referenceValues = arrayOfYupReferences
        .flatMap(reference => {
          const inputToLower = (resolve(reference) || '').toLowerCase();
          return splitWords ? inputToLower.split(/[\s-.,]+/) : inputToLower;
        })
        .filter(reference => reference.trim().length !== 0);
      return !referenceValues.some(reference => nullCheckedAndLoweredValue.includes(reference));
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, notContainsHandler);
