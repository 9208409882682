import retryCallIfNeeded from '../../helpers/restCalls';

export default function genericInfoService(client) {
  const getPersonGenericInfoAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/genericinfo`),
  );
  return {
    getPersonGenericInfoAsync,
  };
}
