import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const contactAddress = (
  state = { contactAddress: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_CONTACT_ADDRESS:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CONTACT_ADDRESS:
      return {
        ...state,
        address: action.address,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_CONTACT_ADDRESS_ERROR:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_CONTACT_ADDRESS_MISSING:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          getStatus: STATUS.MISSING,
        },
      };
    // POST Address
    case ACTIONS.REQUEST_POST_ADDRESS:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_ADDRESS:
      return {
        ...state,
        address: action.address[0],
        addressETag: action.addressETag,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_ADDRESS_POST_ERROR:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // PUT Address
    case ACTIONS.REQUEST_UPDATE_CONTACT_ADDRESS:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_CONTACT_ADDRESS:
      return {
        ...state,
        address: action.address[0],
        addressETag: action.addressETag,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_UPDATE_CONTACT_ADDRESS_ERROR:
      return {
        ...state,
        contactAddressStatus: {
          ...state.contactAddressStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    default:
      return state;
  }
};

export default contactAddress;
