/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isDateGreaterThanStartDate';

function isDateGreaterThanStartDateHandler(ref, msg, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message: msg || '${path} must be the greater than ${reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      let startDate = moment(this.resolve(ref), dateFormat, true);
      // Default to today if start date is invalid
      startDate = startDate.isValid() ? startDate : moment();
      return !value || startDate.diff(moment(value, dateFormat, true), 'day') < 0;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isDateGreaterThanStartDateHandler);
