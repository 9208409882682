import retryCallIfNeeded from '../../helpers/restCalls';

export default function informationService(client) {
  const getPersonInformationAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/information`),
  );
  const postPersonInformationAsync = async (personId, informationPayload) => retryCallIfNeeded(
    client,
    () => client.post(`/api/persons/v1/person/${personId}/information`, informationPayload),
  );
  const putPersonInformationAsync = async (personId, informationPayload, etag) => retryCallIfNeeded(
    client,
    () => {
      const config = {
        headers: {
          'If-Match': etag || 'Default',
        },
      };
      return client.put(`/api/persons/v1/person/${personId}/information/${informationPayload.id}`, informationPayload, config);
    },
  );

  return {
    getPersonInformationAsync,
    postPersonInformationAsync,
    putPersonInformationAsync,
  };
}
