import store from '../../store/configureStore'; // TODO: Fix

export default requestConfig => {
  const { oidc: { user } = { user: {} } } = store.getState();
  const accessToken = user === null ? null : user.access_token;
  if (accessToken !== null && accessToken !== undefined) {
    return {
      ...requestConfig,
      headers: { ...requestConfig?.headers, Authorization: `Bearer ${accessToken}` },
    };
  }
  return requestConfig;
};
