import SET_CART_RESOURCES from './types';

function cartResources(
  state = {
    cartResources: {
      ur_anticipatedStartDate: '',
      ur_campus: '',
      ur_registrationSource: '',
      ur_program: '',
      ur_campaign: '',
      getStartedTargetUrl: '',
    },
  },
  action,
) {
  switch (action.type) {
    case SET_CART_RESOURCES:
      return {
        ...state,
        ...action.cartResources,
      };
    default:
      return state;
  }
}

export default cartResources;
