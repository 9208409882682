import retryCallIfNeeded from '../../helpers/restCalls';

const baseEndpoint = '/api/contacts/v1';

export default function emailService(client) {
  const getContactEmailAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpoint}/emails?linkId=${personId}&linkType=person`));
  const postContactEmailAsync = async payload => retryCallIfNeeded(client, () => client
    .post(`${baseEndpoint}/email`, payload));
  const putContactEmailAsync = async (emailId, payload) => retryCallIfNeeded(client, () => client
    .put(`${baseEndpoint}/email/${emailId}`, payload));
  return {
    getContactEmailAsync,
    postContactEmailAsync,
    putContactEmailAsync,
  };
}
