import { ACTIONS, WORKFLOW_TASK_NAMES, getWorkflowKeyByValue } from './types';
import STATUS from '../../globalStatuses';

const getPriorityWorkflowId = workflows => {
  if (workflows.length < 2) {
    // If there is less than 2 workflows then return the existing workflow or null
    return workflows?.[0]?.workflowId;
  }

  const runningWorkflows = workflows.filter(
    workflow => workflow.status === 'RUNNING',
  );
  if (runningWorkflows.length < 1) {
    // If there are no running workflows then return any existing workflow
    return workflows?.[0]?.workflowId;
  }
  if (runningWorkflows.length === 1) {
    // If there is only 1 running workflow return it.
    return runningWorkflows[0].workflowId;
  }
  runningWorkflows.sort(
    // Sorting B - A. If A has most tasks then the sort will return negative which sorts A before B
    (workflowA, workflowB) => workflowB.tasks.length - workflowA.tasks.length,
  );
  // In this case we have multiple running workflows which we've sorted.
  // Return the workflow with the most tasks (furthest progressed)
  return runningWorkflows[0].workflowId;
};

// eslint-disable-next-line max-len
const defaultWorkflowDetailStatus = Object.keys(WORKFLOW_TASK_NAMES).reduce((previousObject, currentKey) => ({ ...previousObject, [currentKey]: STATUS.UNFETCHED }), {});

function saga(
  state = {
    workflow: null,
    workflowStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
    detailedWorkflowStatus: defaultWorkflowDetailStatus,
    workflowInstanceId: '',
  },
  action,
) {
  let workflowId = state?.workflowInstanceId;
  if (action.type === ACTIONS.RECEIVE_WORKFLOW) {
    const priorityWorkflowId = getPriorityWorkflowId(action.workflow);
    if (priorityWorkflowId) {
      workflowId = priorityWorkflowId;
    }
  }
  switch (action.type) {
    // POST WORKFLOW
    case ACTIONS.REQUEST_START_WORKFLOW:
      return {
        ...state,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_START_WORKFLOW:
      return {
        ...state,
        workflowInstanceId: action.workflowInstanceId,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_START_WORKFLOW_ERROR:
      return {
        ...state,
        error: action.error,
        workflowStatus: {
          ...state.workflowStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // GET Workflow
    case ACTIONS.REQUEST_WORKFLOW:
      return {
        ...state,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_WORKFLOW:
      return {
        ...state,
        workflow: action.workflow,
        workflowInstanceId: workflowId,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_WORKFLOW_ERROR:
      return {
        ...state,
        error: action.error,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_WORKFLOW_MISSING:
      return {
        ...state,
        workflowStatus: {
          ...state.workflowStatus,
          getStatus: STATUS.MISSING,
        },
      };
    // Get Person Task
    case ACTIONS.REQUEST_PERSON_TASK:
      return {
        ...state,
        personTasksStatus: {
          ...state.personTasksStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_TASK:
      return {
        ...state,
        personTasks: action.personTasks,
        personTasksStatus: {
          ...state.personTasksStatus,
          getStatus: STATUS.FETCHED,
          errorData: null,
        },
      };
    case ACTIONS.REQUEST_PERSON_TASK_ERROR:
      return {
        ...state,
        error: action.error,
        personTasksStatus: {
          ...state.personTasksStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // Put Person Task
    case ACTIONS.REQUEST_COMPLETE_PERSON_TASK:
      return {
        ...state,
        personTasksStatus: {
          ...state.personTasksStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_COMPLETE_PERSON_TASK:
      return {
        ...state,
        personTasks: action.personTasks,
        personTasksStatus: {
          ...state.personTasksStatus,
          modifyStatus: STATUS.UPDATED,
          errorData: null,
        },
      };
    case ACTIONS.REQUEST_COMPLETE_PERSON_TASK_ERROR:
      return {
        ...state,
        error: action.error,
        personTasksStatus: {
          ...state.personTasksStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // Post Workflow Task
    case ACTIONS.REQUEST_COMPLETE_WORKFLOW_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(action.payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(action.payload.taskName)],
            modifyStatus: STATUS.UPDATING,
            errorData: action.payload.error,
          },
        },
      };
    case ACTIONS.RECEIVE_COMPLETE_WORKFLOW_TASK:
      return {
        ...state,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(action.payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(action.payload.taskName)],
            modifyStatus: STATUS.UPDATED,
            errorData: action.payload.error,
          },
        },
      };
    case ACTIONS.REQUEST_COMPLETE_WORKFLOW_TASK_ERROR:
      return {
        ...state,
        error: action.payload.error,
        detailedWorkflowStatus: {
          ...state.detailedWorkflowStatus,
          [getWorkflowKeyByValue(action.payload.taskName)]: {
            ...state.detailedWorkflowStatus[getWorkflowKeyByValue(action.payload.taskName)],
            modifyStatus: STATUS.ERROR,
            errorData: action.payload.error,
          },
        },
      };
    default:
      return state;
  }
}

export default saga;
