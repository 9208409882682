import retryCallIfNeeded from '../../helpers/restCalls';

export default function militaryService(client) {
  const getMilitaryInstallationSuggestAsync = async input => {
    let formattedResponseData = [];

    if (input && input.length > 1) {
      const encodedInput = encodeURI(input);
      const responseData = (await client.get(`/api/organizations/v1/military/suggestion/${encodedInput}`)).data;

      if (responseData && Array.isArray(responseData)) {
        formattedResponseData = responseData.map(suggestion => (
          { label: suggestion.name, value: suggestion.id }
        ));
      }
    }
    return formattedResponseData;
  };

  const getVeteranOrganizationSuggestAsync = async input => {
    let formattedResponseData = [];

    if (input && input.length > 1) {
      const encodedInput = encodeURI(input);
      const responseData = (await client.get(`/api/organizations/v1/veteranorg/suggestion/${encodedInput}`)).data;

      if (responseData && Array.isArray(responseData)) {
        formattedResponseData = responseData.map(suggestion => (
          { label: suggestion.name, value: suggestion.id }
        ));
      }
    }
    return formattedResponseData;
  };

  const getPersonMilitaryAsync = async personId => retryCallIfNeeded(
    client,
    () => client.get(`/api/persons/v1/person/${personId}/military`),
  );
  const postPersonMilitaryAsync = async payload => retryCallIfNeeded(
    client,
    () => client.post('/api/persons/v1/person/military', payload),
  );
  const putPersonMilitaryAsync = async (militaryId, payload, etag) => retryCallIfNeeded(
    client,
    () => {
      const config = {
        headers: {
          'If-Match': etag || 'Default',
        },
      };
      return client.put(`/api/persons/v1/person/military/${militaryId}`, payload, config);
    },
  );
  const deletePersonMilitaryAsync = async (personId, militaryId) => retryCallIfNeeded(
    client,
    () => client.delete(`/api/persons/v1/person/${personId}/military/${militaryId}`),
  );

  return {
    getMilitaryInstallationSuggestAsync,
    getVeteranOrganizationSuggestAsync,
    getPersonMilitaryAsync,
    postPersonMilitaryAsync,
    putPersonMilitaryAsync,
    deletePersonMilitaryAsync,
  };
}
