/* eslint-disable no-underscore-dangle */
import url from 'url';

const destinyDefaultUrl = window.__ENV?.REACT_APP_DESTINY_DEFAULT_URL;

const IS_NEW_ACCOUNT_IN_CHECKOUT = 'isNewAccountInCheckout';

const cartResourcesFields = [
  'ur_anticipatedStartDate',
  'ur_campus',
  'ur_registrationSource',
  'ur_program',
  'ur_campaign',
  'getStartedTargetUrl',
  'authdepotRedirectUrl',
];

export const defaultCartResourceValues = {
  ur_anticipatedStartDate: '08/11/2020',
  ur_campus: '31',
  ur_registrationSource: 'NON_DEGREE_DESTINYONE',
  ur_program: 'ND',
  ur_campaign: 'Shopping_Cart',
  getStartedTargetUrl: destinyDefaultUrl,
  authdepotRedirectUrl: '',
};

export const loadCartResources = (redirectUrl, setCartResources) => {
  const { query } = url.parse(window.location.href, true);
  const trimmedQuery = {};
  const cartResources = { ...defaultCartResourceValues, authdepotRedirectUrl: redirectUrl };
  Object.keys(query).forEach(resource => {
    trimmedQuery[resource.trim()] = query[resource];
  });

  cartResourcesFields.forEach(resource => {
    if (!trimmedQuery[resource]) {
      const valueFromSessionStorage = sessionStorage.getItem(resource);
      if (valueFromSessionStorage) {
        cartResources[resource] = valueFromSessionStorage;
      }
    } else {
      cartResources[resource] = trimmedQuery[resource];
      sessionStorage.setItem(resource, cartResources[resource]);
    }
  });

  setCartResources(cartResources);
};

export const setIsNewAccountInCheckout = value => {
  sessionStorage.setItem(IS_NEW_ACCOUNT_IN_CHECKOUT, value);
};

export const getIsNewAccountInCheckout = () => sessionStorage.getItem(IS_NEW_ACCOUNT_IN_CHECKOUT);
export const deleteIsNewAccountInCheckout = () => sessionStorage
  .removeItem(IS_NEW_ACCOUNT_IN_CHECKOUT);
