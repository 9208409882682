import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personName = (
  state = { personName: {} },
  action,
) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PERSON_NAME:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_NAME:
      return {
        ...state,
        name: action.name,
        personNameStatus: {
          ...state.personNameStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_NAME_ERROR:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_NAME_MISSING:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          getStatus: STATUS.MISSING,
        },
      };
    // PUT name
    case ACTIONS.REQUEST_UPDATE_PERSON_NAME:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_UPDATE_PERSON_NAME:
      return {
        ...state,
        nameETag: action.etag,
        name: action.name,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_UPDATE_PERSON_NAME_ERROR:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
      //POST
      case ACTIONS.REQUEST_CREATE_PERSON_NAME:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CREATE_PERSON_NAME:
      return {
        ...state,
        nameETag: action.etag,
        name: action.name,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_CREATE_PERSON_NAME_ERROR:
      return {
        ...state,
        personNameStatus: {
          ...state.personNameStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    default:
      return state;
  }
};

export default personName;
