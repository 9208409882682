/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isInThePast';

function isDateInThePastHandler(message, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message: message || '${path} cannot be in the past',
    test(value) {
      const today = moment().format(dateFormat);
      const todayVal = moment(today, dateFormat);
      const isInThePast = !value || todayVal.diff(moment(value, dateFormat, true), 'day') < 0;
      return isInThePast;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isDateInThePastHandler);
