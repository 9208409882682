import ACTIONS from './types';
import STATUS from '../../globalStatuses';

function csrf(
  state = { csrf: {} },
  action,
) {
  switch (action.type) {
    case ACTIONS.REQUEST_TOKEN:
      return { ...state, status: STATUS.FETCHING };
    case ACTIONS.RECEIVE_TOKEN:
      // Todo: Format the data better
      return { ...state, status: STATUS.FETCHED };
    case ACTIONS.REQUEST_TOKEN_ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        errorDate: {
          error: action.error,
        },
      };
    case ACTIONS.REQUEST_TOKEN_MISSING:
      return {
        ...state,
        status: STATUS.MISSING,
      };
    case ACTIONS.INVALIDATE_TOKEN:
      return { ...state, status: STATUS.INVALIDATED };
    default:
      return state;
  }
}

export default csrf;
