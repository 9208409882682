const ACTIONS = {
  REQUEST_PERSON_NAME: 'REQUEST_PERSON_NAME',
  RECEIVE_PERSON_NAME: 'RECEIVE_PERSON_NAME',
  REQUEST_PERSON_NAME_MISSING: 'RECEIVE_PERSON_NAME_MISSING',
  REQUEST_PERSON_NAME_ERROR: 'REQUEST_PERSON_NAME_ERROR',

  REQUEST_UPDATE_PERSON_NAME: 'REQUEST_UPDATE_PERSON_NAME',
  RECEIVE_UPDATE_PERSON_NAME: 'RECEIVE_UPDATE_PERSON_NAME',
  REQUEST_UPDATE_PERSON_NAME_ERROR: 'REQUEST_UPDATE_PERSON_NAME_ERROR',

  REQUEST_CREATE_PERSON_NAME: 'REQUEST_CREATE_PERSON_NAME',
  RECEIVE_CREATE_PERSON_NAME: 'RECEIVE_CREATE_PERSON_NAME',
  REQUEST_CREATE_PERSON_NAME_ERROR: 'REQUEST_CREATE_PERSON_NAME_ERROR',
};

export default ACTIONS;
