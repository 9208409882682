/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import moment from 'moment';

const extensionMethodName = 'isInTheFuture';

function isDateInTheFutureHandler(message, dateFormat = 'MM/DD/YYYY') {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message: message || '${path} cannot be now or in the future',
    test(value) {
      const today = moment().format(dateFormat);
      const todayVal = moment(today, dateFormat);
      const isInTheFuture = !value || todayVal.diff(moment(value, dateFormat, true), 'day') > 0;
      return isInTheFuture;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isDateInTheFutureHandler);
