import STATUS from '../../../globalStatuses';
import ACTIONS from './types';

const personCitizenship = (
  state = { citizenship: {} },
  action,
) => {
  switch (action.type) {
    // GET
    case ACTIONS.REQUEST_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.REQUEST_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.REQUEST_PERSON_CITIZENSHIP_MISSING:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          getStatus: STATUS.MISSING,
        },
      };

    // POST
    case ACTIONS.REQUEST_POST_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_POST_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT
    case ACTIONS.REQUEST_PUT_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
        },
      };
    case ACTIONS.RECEIVE_PUT_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenshipETag: action.etag,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PUT_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE
    case ACTIONS.REQUEST_DELETE_PERSON_CITIZENSHIP:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATING,
        },
      };
    case ACTIONS.RECEIVE_DELETE_PERSON_CITIZENSHIP:
      return {
        ...state,
        citizenship: action.citizenship,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_PERSON_CITIZENSHIP_ERROR:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // CLEAR STATUSES
    case ACTIONS.CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS:
      return {
        ...state,
        personCitizenshipStatus: {
          ...state.personCitizenshipStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
};

export default personCitizenship;
