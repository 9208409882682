const ACTIONS = {
  REQUEST_CONTACT_PHONE: 'REQUEST_CONTACT_PHONE',
  RECEIVE_CONTACT_PHONE: 'RECEIVE_CONTACT_PHONE',
  REQUEST_CONTACT_PHONE_MISSING: 'REQUEST_CONTACT_PHONE_MISSING',
  REQUEST_CONTACT_PHONE_ERROR: 'REQUEST_CONTACT_PHONE_ERROR',
  REQUEST_POST_PHONE: 'REQUEST_POST_PHONE',
  RECEIVE_POST_PHONE: 'RECEIVE_POST_PHONE',
  REQUEST_PHONE_POST_ERROR: 'REQUEST_PHONE_POST_ERROR',
  REQUEST_UPDATE_PHONE: 'REQUEST_UPDATE_PHONE',
  RECEIVE_UPDATE_PHONE: 'RECEIVE_UPDATE_PHONE',
  REQUEST_PHONE_UPDATE_ERROR: 'REQUEST_PHONE_UPDATE_ERROR',
};

export default ACTIONS;
