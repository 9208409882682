const STATUS = {
  ERROR: 'ERROR',
  FETCHED: 'FETCHED',
  FETCHING: 'FETCHING',
  INVALIDATED: 'INVALIDATED',
  MISSING: 'MISSING',
  UPDATED: 'UPDATED',
  UPDATING: 'UPDATING',
  UNFETCHED: 'UNFETCHED',
};

export default STATUS;
