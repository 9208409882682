/* eslint-disable no-template-curly-in-string */
// https://github.com/jquense/yup#extending-schema-types
// Test exclusive is false by default
import * as yup from 'yup';

const extensionMethodName = 'isValueAvailable';

function isValueAvailableHandler(referenceField, message) {
  return this.test({
    name: extensionMethodName,
    exclusive: false,
    message: message || '${path} is already taken',
    test() {
      const isValueAvailable = this.resolve(referenceField);
      return isValueAvailable;
    },
  });
}

yup.addMethod(yup.string, extensionMethodName, isValueAvailableHandler);
